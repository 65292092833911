.sustainability-social-impacts {
  &-container {
    @apply col-span-12 z-10;

    &:not(.sustainability-social-impacts-container--after-sales) {
      .bg-luveBlue { //sass-lint:disable-line class-name-format
        @apply bg-opacity-70 xl:bg-opacity-100 #{!important}; //sass-lint:disable-line no-important
      }
    }

    &__title {
      @apply pt-12 pb-12 px-7 text-4xl text-center text-white z-10;
    }
  }
}

.sustainability-social-impact {
  &__left-box {

    .sustainability-social-impact {
      &__content {
        @apply  flex flex-col h-full pl-20 3xl:pl-94 pr-52 pt-48 relative w-full z-10;
      }

      &__title {
        @apply font-medium mb-5 text-4xl text-white uppercase;
      }
    }
  }

  &__right-box {
    .sustainability-social-impact {
      &__content {
        @apply flex flex-col h-full pl-40 3xl:pr-[32rem] pt-48 relative w-full;
      }

      &__title {
        @apply font-medium mb-5 text-3xl text-white normal-case;
      }
    }
  }

  &:nth-child(odd) {
    .sustainability-social-impact__left-box {
      @apply after:bg-gradient-to-l after:absolute after:from-luveBlue after:via-luveBlue after:to-transparent after:w-full after:h-full after:top-0;
    }

    .sustainability-social-impact__right-box {
      .sustainability-social-impact {
        &__content {
          @apply pr-56 2xl:pr-96 3xl:pr-[32rem];
        }
      }
    }
  }

  &:nth-child(even) {
    .sustainability-social-impact__left-box {
      @apply after:bg-gradient-to-r after:absolute after:from-luveBlue after:via-luveBlue/20 after:to-transparent after:w-full after:h-full after:top-0 order-2;

      .sustainability-social-impact {
        &__content {
          @apply pl-28 lg:pl-40 pr-56 2xl:pr-96 3xl:pr-[32rem];
        }

        &__title {
          @apply font-medium mb-5 text-3xl text-white normal-case;
        }
      }
    }

    .sustainability-social-impact__right-box {
      .sustainability-social-impact {
        &__content {
          @apply pl-28 3xl:pl-94 pr-52;
        }

        &__title {
          @apply font-medium mb-5 text-4xl text-white uppercase;
        }
      }
    }
  }
}
