.exhibitions {
  @apply col-span-12 mb-10;

  &__container {
    @apply container relative;
  }

  &__header {
    @apply mx-4;

    h2 {
      @apply font-bold my-8 text-7xl text-luveBlue;
    }

    img {
      @apply mb-10;
    }
  }

  &__list {
    @apply mx-4 overflow-x-auto md:overflow-hidden;
  }

  &__table {
    @apply table-auto w-full;

    thead {
      @apply bg-luveLightBlue text-white font-bold;
    }

    tbody {
      tr {
        @apply border-l border-r hover:bg-luveGrey-lightest;

        & + tr {
          @apply border-t;

          &:last-of-type {
            @apply border-b;
          }
        }
      }
    }

    &__row--linked {
      @apply cursor-pointer;
    }

    td,
    th {
      @apply p-2 whitespace-nowrap md:whitespace-normal;
    }

    th {
      @apply text-left;
    }

    &--date,
    &--name,
    &--stand,
    &--location,
    &--participants {
      @apply align-top;
    }

    &--date {
      @apply font-bold text-luveLightBlue w-2/12;
    }

    &--name {
      @apply font-bold text-black w-2/12;
    }

    &--stand {
      @apply text-luveBlue;
    }

    &--location {
      @apply text-black w-3/12;
    }

    &--participants {
      @apply text-black;
    }
  }
}
