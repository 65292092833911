.red-awareness {
  &-container {
    @apply bg-luveBlue bg-fixed col-span-12;

    &__background-image {
      @apply fixed h-screen w-fit top-0 z-0 tablet-max:hidden;
    }

    &__title {
      @apply pt-8 mb-12 text-4xl text-center text-white uppercase;
    }

    &__content {
      @apply relative z-10;

      &-items {
        @apply container-wide;
      }
    }
  }

  &-box {
    @apply col-span-12 md:col-span-9 md:odd:col-start-4 md:even:col-start-1 md:even:-scale-x-100 md:mb-5 relative;

    &:nth-child(even) {
      .red-awareness-box {
        &__background-image,
        &__logo {
          @apply md:-scale-x-100;
        }
      }
    }

    &:nth-child(odd) {
      .red-awareness-box__description-text {
        @apply md:-scale-x-100;
      }
    }

    &__content {
      @apply h-max md:h-full overflow-hidden relative;

      @screen md {
        clip-path: path('M1441.25,.25V360.25H105.1c-6.82-.09-13.02-3.94-16.15-9.99L2.75,190.26c-3.34-6.25-3.34-13.76,0-20.01L88.95,10.25C92.08,4.19,98.28,.34,105.1,.25H1441.25Z');
      }
    }

    &__description {
      @apply
      md:absolute
      duration-300
      h-full
      flex
      flex-row
      group-hover:top-0
      group-hover:duration-300
      group-hover:opacity-100
      justify-center
      md:justify-end
      items-center
      lg:opacity-0
      pb-12
      md:pb-0
      px-8
      md:pr-40
      md:pl-20
      lg:px-40
      relative
      md:top-90
      md:-scale-x-100
      w-full
      z-10;

      @screen tablet-max {
        @apply
        after:absolute
        after:bg-luveBlue
        after:h-full
        after:-top-28
        after:w-full
        after:scale-x-200
        after:left-52
        sm:after:left-72;

        @screen mobile-max {
          @apply after:left-0 after:scale-100;
        }

        &:after {
          clip-path: path('M0,117.8C0,57.3,25.4,1.8,67.3,0l285.4,50c0,0,67.3,7.3,67.3,67.8');
        }
      }

      &-mobile-border {
        @screen tablet-max {
          @apply
          absolute
          h-full
          -top-32
          w-full
          scale-x-200
          left-52
          sm:left-72;
        }

        @screen mobile-max {
          @apply left-0 scale-100;
        }

        clip-path: path('M0,119.5C0,59,25.4,1.8,67.3,0l285.4,50c0,0,67.3,9,67.3,69.5');
      }

      &-text {
        @apply flex flex-col z-10;

        p {
          @apply mb-5;
        }

        strong {
          @apply font-bold -ml-4 text-lg lg:text-3xl text-white;
        }

        ul {
          @apply list-disc;

          li {
            @apply font-medium mb-1 text-sm lg:text-lg text-white;
          }
        }
      }
    }

    &__description {
      @apply md:bg-transparent tablet-max:bg-none #{!important}; //sass-lint:disable-line no-important
    }

    &__logo-hexagon-container {
      @apply
      absolute
      bg-white/10
      backdrop-blur-md
      group-hover:ease-in-out
      group-hover:-rotate-60
      group-hover:duration-300
      md:top-1/2
      md:-translate-y-1/2
      md:-left-11
      md:scale-100
      scale-75
      -left-4
      top-24
      w-48
      h-48
      ease-in-out
      duration-300
      z-10;
      clip-path: path('M134.5,157.6c-1.8,3.1-5.1,5-8.7,5l-63.6-0.1c-3.6,0-6.9-1.9-8.6-5l-31.9-55.1c-1.8-3.1-1.8-6.9,0-10l31.7-54.9  c1.8-3.1,5.1-5,8.7-5l63.6,0.1c3.6,0,6.9,1.9,8.6,5l31.9,55.1c1.8,3.1,1.8,6.9,0,10L134.5,157.6z');

      &--mobile {
        @apply
        group-hover:rotate-0;
      }
    }

    &__logo-hexagon {
      @apply h-full w-full relative;

      &-bg {
        @apply absolute h-full w-full top-0 opacity-0 group-hover:opacity-100 group-hover:duration-300;
      }
    }

    &__logo {
      @apply scale-75 md:scale-100 absolute top-1/2 -translate-y-1/2 z-10;

      &--mobile {
        @apply left-1/2 -translate-x-1/2;
      }
    }
  }
}
