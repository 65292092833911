.homepage {
  &-products-container {
    @apply
    col-span-12
    relative
    tablet-max:before:h-40
    tablet-max:before:w-full
    tablet-max:before:absolute
    tablet-max:before:bg-gradient-to-t
    tablet-max:before:from-luveBlue
    tablet-max:before:to-transparent
    tablet-max:before:left-0
    tablet-max:before:-top-40;

    &__title {
      @apply
      py-12
      font-light
      tablet-max:mb-5
      text-6xl
      text-center
      text-white
      uppercase;
    }


    &__content {
      > .homepage-product-bg-image {
        @apply
        after:absolute
        after:from-luveLightBlue
        after:bg-gradient-to-r
        lg:after:bg-gradient-to-r
        after:h-full
        after:to-transparent
        after:top-0
        after:w-full
        even:after:from-luveBlue
        min-h-100
        mb-3
        overflow-hidden
        relative;

        & ~ .homepage-product-bg-image {
          @apply
          after:from-luveBlue
          lg:after:from-luveLightBlue
          lg:even:after:from-luveBlue
          after:bg-gradient-to-t
          lg:after:bg-gradient-to-r;
        }
      }
    }
  }

  &-product {
    @apply
    bg-white
    border
    border-l-0
    border-t-0
    col-span-12
    md:border-b
    border-luveBlue
    first:border-l
    flex
    flex-auto
    flex-col
    items-center
    md:col-span-6
    overflow-hidden
    relative
    pb-11
    pl-12
    pr-8
    pt-7
    tablet-max:mb-3
    3xl:col-span-3;

    &__product-content {
      @apply
      ease-in-out
      duration-300
      flex
      flex-col
      big-mobile-max:-mt-32
      big-mobile-max:mb-24
      big-mobile-max:overflow-visible
      group-hover:ease-in-out
      group-hover:duration-300
      group-hover:xs-to-md:-mt-32
      group-hover:xs-to-md:mb-24
      group-hover:md-to-lg:-mt-26
      group-hover:md-to-lg:mb-12
      group-hover:lg:mb-0
      group-hover:lg:-mt-24
      group-hover:overflow-visible
      max-h-10
      overflow-hidden
      self-start;
    }

    &__description {
      @apply
      duration-300
      ease-in-out
      lg:max-w-68
      max-w-68
      md:max-w-56
      text-sm
      text-luveBlue;

      p {
        @apply
        duration-150
        mb-6
        big-mobile-max:opacity-100
        xs:opacity-0
        group-hover:opacity-100
        group-hover:duration-700;
      }
    }

    &__product-images {
      @apply
      min-h-60;
    }

    &__product-image {
      @apply
      duration-300
      ease-in-out
      relative
      z-10;
    }

    &__background-image {
      @apply
      absolute
      ease-in-out
      duration-300
      left-0
      top-20;
    }

    &__product-image,
    &__background-image {
      @apply
      big-mobile-max:scale-75
      big-mobile-max:translate-x-26
      big-mobile-max:-translate-y-12
      group-hover:ease-in-out
      group-hover:duration-300
      group-hover:scale-110
      group-hover:translate-x-26
      group-hover:-translate-y-12;
    }

    &__subtitle {
      @apply
      font-medium
      text-sm
      text-luveBlue
      uppercase;
    }

    &__title {
      @apply
      font-bold
      big-mobile-max:mb-2
      group-hover:mb-2
      text-lg
      text-luveBlue
      uppercase;
    }

    &__line-name {
      @apply
      font-bold
      mr-3
      text-luveBlue
      text-3xl
      uppercase;

      &-trapezoid {
        @apply
        after:absolute
        after:bg-white
        after:h-10
        after:-top-3
        after:-right-3
        after:w-5
        after:-rotate-30
        bg-luveLightBlue
        font-bold
        py-1
        pl-2
        pr-4
        relative
        text-white
        text-xs
        uppercase;
      }
    }

    &-bg-image {
      &__product-content {
        @apply
        big-mobile-max:bottom-28
        big-mobile-max:overflow-visible
        group-hover:bottom-28
        group-hover:ease-in-out
        group-hover:duration-500
        group-hover:overflow-visible;

       &--cta-only {
        @apply group-hover:mb-8 big-mobile-max:mb-8;
       }
      }

      &__description p {
        @apply
        big-mobile-max:opacity-100
        group-hover:opacity-100
        group-hover:duration-700 transition;
      }

      &__product-image,
      &__background-image {
        @apply
        big-mobile-max:scale-110
        group-hover:ease-in-out
        group-hover:duration-300
        group-hover:scale-110
        h-full;
      }

      &__product-image {
        @apply object-cover;
      }
    }

    &__product-link {
      @apply
      cursor-pointer
      flex
      flex-row
      items-center
      mr-3
      font-medium
      text-base
      z-50;

      &-logo {
        @apply
        duration-500
        group-hover/link:rotate-90
        mr-4
        rotate-0
        self-center;
      }

      &-text {
        @apply
        font-medium
        mr-4
        text-luveBlue
        text-base;

        &--white {
          @apply text-white;
        }
      }

      &-icons-container {
        @apply
        flex
        max-w-8
        overflow-hidden;
      }

      &-icon {
        &--left {
          @apply
          duration-700
          ease-in-out
          flex
          big-mobile-max:translate-x-4
          group-hover/link:translate-x-4
          items-center
          -translate-x-8 ;
        }

        &--right {
          @apply
          duration-700
          ease-in-out
          flex
          big-mobile-max:translate-x-4
          group-hover/link:translate-x-4
          items-center
          -translate-x-2 ;
        }
      }
    }
  }
}
