.contacts {
  &-text__description {
    p {
      @apply font-medium text-base text-white;
    }

    strong {
      @apply font-bold text-xl;
    }
  }

  &__link {
    @apply border-b-2
    border-b-luveLightBlue
    flex
    flex-row
    items-baseline
    whitespace-nowrap
    font-medium
    text-2xl
    text-white
    pb-2.5
    w-fit;

    &-arrow {
      @apply lg:w-0
      lg:opacity-0
      object-contain
      ml-5
      lg:ml-0
      transition-all
      duration-300;
    }

    & + .contacts__link {
      @apply mt-8;
    }
  }

  &__form {
    input[type=text], //sass-lint:disable-line no-qualifying-elements
    select,
    textarea {
      @apply border
      border-white/40
      font-medium
      focus:bg-gradient-to-b
      focus:from-luveBlue
      focus:to-luveLightBlue-dark
      bg-luveLightBlue/30
      backdrop-blur-xl
      backdrop-brightness-105
      mb-2
      w-full
      px-5
      py-4
      text-sm
      text-white
      placeholder:text-white
      placeholder:font-medium;
    }

    input[type=checkbox] { //sass-lint:disable-line no-qualifying-elements
      @apply hidden;
    }

    input[type=checkbox]:checked + label { //sass-lint:disable-line no-qualifying-elements
      @apply bg-luveBlue;
    }

    select {
      @apply cursor-pointer;

      option {
        @apply bg-luveBlue;
      }
    }

    &-success-message {
      @apply
      flex
      font-medium
      mb-5
      text-base
      text-white;
    }

    &-checkboxes .errorlist {
      @apply m-0;
    }

    &--after-sales {
      .errorlist {
        @apply mb-2 mt-1 text-sm;
      }

      input[type=checkbox] { //sass-lint:disable-line no-qualifying-elements
        @apply block opacity-0 absolute bottom-2 left-2.5;
      }

      .contacts__form-checkboxes {
        @apply relative;
      }
    }
  }

  &__right-column {
    .djangocms-googlemap {
      @apply border-4 border-luveLightBlue;
    }

    .djangocms-googlemap-container  {
      @apply w-full #{!important}; //sass-lint:disable-line no-important
    }
  }
}
