.red {
  &-header {
    @apply
    bg-luveBlue
    col-span-12
    relative
    after:absolute
    after:bg-gradient-radial
    after:from-luveBlue-light/20
    after:h-full
    after:to-transparent
    after:top-0
    after:w-full
    min-h-[48rem]
    xl:min-h-full
    overflow-hidden
    z-10;

    &__video-container {
      @apply
      lg:block
      hidden
      min-h-165
      max-h-240
      mix-blend-color-dodge
      opacity-20
      overflow-hidden
      relative;
    }

    &__content {
      @apply lg:absolute h-full top-0 w-full z-10;
    }

    &__title {
      @apply italic mb-10 pl-8 pr-16 pt-14 lg:pt-0 lg:px-0 text-6xl lg:text-7xl text-white;
    }

    &__subtitle {
      @apply font-medium italic lg:mt-12 xl:mt-24 mb-12 pt-3 text-4xl text-white hidden lg:block;
    }

    &__overlay-image {
      @apply
      absolute
      mix-blend-color-dodge
      -right-28
      sm:-right-48
      md:-right-80
      md:top-28
      lg:-right-90
      2xl:right-0
      3xl:right-0
      top-[40rem]
      2xs:top-[35rem]
      xs:top-96
      xs:scale-75
      2xl:scale-100;
    }

    &__overlay-image {
      @apply
      sm:top-60
      md:top-0
      lg:-top-20
      3xl:top-0
      #{!important}; //sass-lint:disable-line no-important

    }

    &__primary-description {
      @apply
      before:absolute
      before:w-80
      before:-left-80
      before:h-full
      before:bg-luveLightBlue/70
      before:top-0
      bg-gradient-to-r
      from-luveLightBlue/70
      via-luveLightBlue-light/25
      to-transparent
      relative
      text-lg
      lg:text-3xl
      mb-12
      pb-12
      pl-8
      lg:pl-0
      pr-16
      lg:pr-32
      pt-14
      text-white
      w-full
      md:w-162
      lg:w-244;
    }

    &__secondary-description {
      @apply
      hidden
      italic
      md:block
      text-3xl
      text-white
      pl-8
      lg:pl-0
      lg:pb-0
      md:pr-56
      xl:pr-80
      2xl:pr-[40rem];

      &--mobile {
        @apply
        italic
        md:hidden
        pb-20
        pl-8
        pr-16
        text-xl
        text-white;
      }
    }
  }
}
