.applications-header {
  &-box {
    @apply
    cursor-pointer
    lg:backdrop-blur-sm
    lg:border
    lg:border-luveLightBlue-lighter
    flex-auto
    items-center
    lg:justify-center
    lg:px-4
    pb-6
    pt-10
    w-36
    xl:w-44;

    &--active {
      @apply lg:bg-luveLightBlue;
    }

    &__description {
      @apply bg-luveBlue border border-luveLightBlue p-6 lg:p-9 w-full;

      p {
        @apply font-light text-sm lg:text-lg text-white whitespace-nowrap relative my-2 lg:px-4;

        &:not(:last-of-type) {
          @apply
          after:mx-2
          lg:after:ml-6
          after:content-['|'];
        }
      }
    }
  }

  .control-line {
    &.active {
      @apply after:bg-white #{!important}; //sass-lint:disable-line no-important
    }
  }
}
