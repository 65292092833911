.applications-text-image {
  &__subtitle li {
    @apply
    before:bg-luveLightBlue-light
    before:h-1
    before:left-0
    before:rounded-full
    before:w-1
    before:mr-2
    flex
    flex-row
    items-center
    relative;
  }

  &__image-container {
    @apply
    col-span-12
    lg:col-span-6
    px-5
    xl:px-0;
  }

  &__image-caption-over {
    @apply
    font-bold
    mb-5
    text-center
    text-lg
    text-white;
  }

  &__image-caption-under {
    @apply
    bg-luveBlue
    font-medium
    px-7
    py-4
    text-center
    text-2sm
    text-white
    uppercase
    w-full;
  }

  &__description {
    @apply
    col-span-12
    lg:col-span-6
    flex
    flex-col
    justify-between
    px-8
    xl:px-0
    pt-4;
  }

  &__logo {
    @apply
    self-center
    max-w-48
    w-20
    xl:w-auto;
  }

  &.bg-white {
    &:last-of-type {
      @apply lg:mb-16;
    }
  }

  &__buttons-container {
    @apply flex
    flex-col
    md:flex-row
    gap-4
    my-6;
  }

  &__button {
    @apply bg-luveLightBlue
    hover:text-luveLightBlue
    hover:bg-white
    border
    border-luveLightBlue
    font-medium
    flex
    flex-row
    items-center
    justify-center
    gap-1.5
    px-5
    lg:px-12
    py-4.5
    text-lg
    text-white
    uppercase
    w-full
    lg:w-fit;

    &-icon {
      @apply brightness-0
      invert
      group-hover:filter-none;
    }
  }
}

.applications-header {
  & + .applications-text-image {
    @apply mt-[48rem] lg:mt-0;

    &:not(.applications-text-image--with-background) {
      @apply
      lg:pt-11
      pt-10;
    }
  }
}
