@mixin close-icon {
  @apply
  after:absolute
  after:left-[15px]
  after:content-['']
  after:h-6
  after:w-0.5
  after:bg-luveLightBlue
  before:absolute
  before:left-[15px]
  before:content-['']
  before:h-6
  before:w-0.5
  before:bg-luveLightBlue
  after:-rotate-45
  before:rotate-45
  relative
  w-6
  h-6;
}

.interactive-map {
  &__production-units-box {
    @apply hidden;

    &.active {
      @apply block;
    }
  }

  &__commercial-offices,
  &__production-units {
    &-container {
      @apply bg-luveBlue md:bg-inherit block md:grid md:grid-cols-interactiveMapsGrid;
    }
  }

  &__commercial-office {
    @apply cursor-pointer px-3 lg:px-6 xl:px-12 py-3.5 bg-luveBlue odd:bg-gradient-to-l from-luveBlue to-interactiveMapsBlue;

    &-country {
      @apply text-luveLightBlue font-bold text-lg sm:text-base mb-0.5 uppercase;
    }

    &-city {
      @apply font-medium text-white text-base sm:text-xs mb-px;
    }
  }

  &__map {
    @apply w-full h-full pb-24;

    &-container {
      @apply hidden md:block relative bg-interactiveMapsBlue-dark pb-20;
    }

    &-title {
      @apply bg-interactiveMapsBlue-dark font-medium italic text-white text-4xl py-9 px-28;
    }
  }

  &__background {
    @apply absolute z-[1] bg-bottom bg-cover w-full h-full top-0 left-0;
  }

  // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
  &__countries-list,
  &__units-list {
    @apply overflow-y-hidden md:overflow-y-scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }
  // sass-lint:enable no-vendor-prefixes, no-misspelled-properties

  &__units-list {
    @apply bg-luveBlue pl-4 xl:pl-12 pt-2 pr-4 hidden lg:block;
  }

  &__production-unit {
    @apply mb-7;

    img {
      @apply mb-2;
    }

    &-name,
    &-location {
      @apply text-white font-medium text-2sm xl:text-sm;
    }

    &-box {
      &--logo {
        @apply mb-3;
      }

      &--name,
      &--location {
        @apply text-white font-medium text-sm xl:text-base 2xl:text-lg normal-case;
      }

      &--description {
        @apply text-white font-light text-2sm xl:text-sm 2xl:text-base mt-3 normal-case;
      }

      &--footer {
        @apply mt-5 flex justify-between items-center;
      }

      &--link {
        @apply text-luveLightBlue text-2sm xl:text-sm uppercase font-bold;

        &-icon {
          @apply inline-block align-baseline ml-2;
        }
      }
    }
  }

  &__units-by-country {
    @apply absolute flex bottom-4 z-20 justify-center w-full;

    &--country-name {
      @apply border border-luveLightBlue text-white font-medium text-2sm xl:text-sm uppercase px-2 py-0.5 xl:px-3.5 xl:py-1 mr-2 xl:mr-4 cursor-pointer relative;

      &.active {
        @apply bg-luveLightBlue;
      }
    }
  }

  &__production-units {
    &-container {
      @apply bg-luveBlue lg:bg-inherit;
    }

    &-map-container {
      @apply hidden lg:block;
    }

    &-box {
      @apply
      border-[3px]
      border-luveLightBlue
      cursor-auto
      absolute
      rounded-tr-[50px]
      px-5
      py-6
      backdrop-blur-[3px]
      bg-interactiveMapsBlue-darker/80
      left-0
      bottom-11
      2xl:min-w-[320px]
      xl:min-w-[250px]
      min-w-[200px];

      &.grid-layout.active {
        @apply grid grid-cols-4 w-[780px] xl:w-[900px] 2xl:w-[1152px] gap-x-4 gap-y-6 xl:gap-y-8 xl:gap-x-5 2xl:gap-x-6 2xl:gap-y-10;

        .interactive-map__production-unit-box {
          @apply flex flex-col;

          &--logo {
            @apply w-fit;
          }

          &--description {
            @apply mb-3;
          }

          &--footer {
            @apply mt-auto;
          }
        }
      }

      &--close {
        &-container {
          @apply relative;
        }

        @include close-icon;
        @apply text-luveLightBlue cursor-pointer absolute right-0 bottom-0;
      }
    }
  }

  &__production-units-mobile {
    @apply overflow-x-scroll;
  }

  &__production-unit-mobile {
    @apply px-5 py-7 min-w-[45%] md:min-w-[30%];

    img {
      @apply mb-7;
    }

    &-name,
    &-location {
      @apply text-white font-medium text-sm;
    }
  }

  &__units-by-country-mobile {
    &-container {
      @apply p-9 bg-gradient-to-b from-luveLightBlue-darker to-luveBlue-light;

      &--multiple {
        @apply flex overflow-x-scroll;

        .interactive-map__units-by-country-mobile-header {
          @apply min-w-[35%] flex-col mr-11 content-start;

          p {
            @apply text-3xl tracking-[5.5px];
          }

          img {
            @apply mr-0;
          }
        }

        .interactive-map__units-by-country-mobile-body {
          @apply flex;
        }
      }
    }

    &--multiple {
      @apply min-w-[25%] md:min-w-[33%] lg:min-w-[40%] mr-6;

      img {
        @apply mb-3.5;
      }
    }

    &-header {
      @apply flex items-center flex-wrap;

      p {
        @apply font-bold text-white text-lg uppercase mb-11;
      }

      img {
        @apply mr-8 mb-11;
      }
    }

    &--name,
    &--location {
      @apply font-medium text-white text-base;
    }

    &--location {
      @apply mb-3.5;
    }

    &--description {
      @apply text-white text-sm mb-6;
    }

    &--details {
      @apply text-luveLightBlue font-medium text-sm uppercase;

      .interactive-map__units-by-country-mobile--details-icon {
        @apply inline-block align-baseline ml-2 mb-0;
      }
    }
  }
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  @apply hidden;
}

.jvectormap-container {
  @apply z-[2];
}
