@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'custom_style';
@import 'interactive_map';
@import 'navigation';
@import 'footer';
@import 'homepage-header';
@import 'homepage-products';
@import 'homepage-applications';
@import 'bottom-strip-boxes';
@import 'group';
@import 'red-header';
@import 'red-awareness';
@import 'red-solutions';
@import 'sustainability-header';
@import 'sustainability-social-impacts';
@import 'sustainability-awards';
@import 'news';
@import 'exhibitions';
@import 'personal-area';
@import 'header';
@import 'applications-header';
@import 'applications-text-image';
@import 'contacts';
@import 'development';
@import 'awards-list';
@import 'error-pages';
@import 'documents';
@import 'after-sales';
@import 'simple-content-plugin';
@import 'homepage-global-presence';
@import 'newsletter';
@import 'slick-slider';
@import 'production-unit';
@import 'sales-contact-detail';
@import 'sales-contacts';
