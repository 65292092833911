.footer {
  &-prefooter {
    @apply  bg-luveBlue-dark;

    &__logos {
      @apply
      container-narrow
      gap-x-4
      grid
      grid-cols-12
      lg:px-0
      px-16
      py-12
      xl:gap-x-0;
    }
  }

  &__logo {
    @apply  col-span-6 md:col-span-4 lg:col-span-2 flex lg:justify-center mb-12 lg:mb-0;
  }

  &__link {
    @apply font-bold text-luveBlue text-sm uppercase;

    &:hover {
      @apply text-luveLightBlue;
    }
  }

  &-main-content {
    @apply
    border-t-8
    border-luveLightBlue
    border-b-half-px
    border-b-luveBlue
    container-wide
    flex
    justify-center
    pt-16
    relative;

    &__container {
      @apply
      grid
      grid-cols-12
      max-w-screen-2xl
      lg:px-5;
    }

    &__left-column {
      @apply
      border-b-half-px
      border-luveBlue
      lg:border-b-0
      lg:border-r-half-px
      col-span-12
      lg:col-span-4
      lg:px-0
      pb-12
      px-5
      xl:border-b-0;
    }

    &__center-column {
      @apply
      border-b-half-px
      border-luveBlue
      col-span-12
      2xl:flex
      2xl:flex-col
      2xl:justify-between
      lg:border-b-0
      lg:border-r-half-px
      lg:col-span-4
      pb-12
      px-5
      xl:px-12;
    }

    &__right-column {
      @apply
      col-span-12
      lg:col-span-3
      2xl:col-span-4
      lg:pt-0
      lg:pl-20
      pb-12
      px-5
      pr-10
      xl:pr-0
      pt-12;
    }

    .footer-text {
      @apply mb-5
      last:mb-0
      last:pt-7;

      &__text {
        @apply
          italic
          lg:text-xl
          mb-8
          last:mb-0
          text-sm
          text-luveBlue/50;
      }
    }

    .footer-contact {
      &__address {
        @apply w-full text-sm not-italic font-normal text-luveBlue/50;

        em {
          @apply italic;
        }
      }

      &__label {
        @apply mb-1 text-xs capitalize text-luveBlue/50;
      }

      &__contact-anchor {
        @apply mb-3;

        a {
          @apply font-bold lg:text-lg text-luveBlue hover:text-luveLightBlue;
        }
      }
    }

    .footer-claim {
      @apply text-3xl italic lg:text-4xl text-luveBlue/50;
    }

    &__back-to-top {
      @apply
      absolute
      bg-custom-circle
      bg-cover
      bg-no-repeat
      cursor-pointer
      flex
      h-12
      hover:scale-105
      items-center
      justify-center
      lg:w-16
      lg:h-16
      right-5
      w-12 ;
    }
  }

  &-menu {
    @apply
    list-none
    flex
    flex-col
    flex-wrap
    gap-y-8
    lg:mt-0
    max-h-72
    mb-12
    lg:mb-21
    mt-8
    xl:max-h-96
    2xl:max-h-72;

    .navigation__item {
      @apply font-bold
      hover:text-luveLightBlue
      text-luveBlue
      text-xl
      uppercase;
    }
  }

  &-socials {
    @apply
    flex
    flex-row
    gap-8
    lg:justify-center;

    &__social {
      @apply
      bg-custom-circle
      bg-cover
      bg-no-repeat
      cursor-pointer
      flex
      h-16
      items-center
      justify-center
      lg:w-20
      lg:h-20
      w-16 ;

      &-icon {
        @apply
        group-hover:w-8
        w-5;
      }
    }
  }

  &-company-name {
    @apply
    container-wide
    flex
    justify-center;

    &__container {
      @apply
      max-w-screen-2xl
      mb-0.5
      px-2
      py-4
      xl:py-12
      xl:px-5
      w-full;
    }
  }

  &-subfooter {
    @apply
    bg-luveBlue-dark
    py-7
    lg:py-5;

    &__container {
      @apply
      container-wide
      flex
      justify-center;
    }

    &__content {
      @apply flex
      flex-col
      items-center
      justify-between
      lg:flex-row
      lg:px-5
      max-w-screen-2xl
      w-full;

      .footer-text {
        @apply
        flex
        flex-1
        order-2
        lg:order-1
        opacity-50
        lg:opacity-100
        mb-5
        lg:mb-0
        text-sm
        lg:text-lg
        text-white;
      }
    }

    &__policies {
      @apply flex
      flex-1
      flex-row
      justify-end
      order-3
      text-white;
    }

    &__policy {
      @apply border-r
      border-r-white
      first:pl-0
      pl-4
      pr-4
      last:border-none
      last:pr-0;

      &-link {
        @apply
        hover:underline
        text-sm
        lg:text-lg
        uppercase;
      }
    }

    &__newsletter-subscribe {
      @apply flex
      flex-1
      font-bold
      mb-9
      lg:mb-0
      lg:order-2
      order-1
      justify-center
      text-white
      text-sm
      lg:text-xl;

      a {
        @apply hover:underline;
      }
    }
  }

  &__text {
    @apply mb-5 text-sm font-normal text-luveBlue/50;
  }
}
