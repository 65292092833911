.applications-header {
  transition: background .5s linear;

  &-box__description {
    display: none;
  }
}

.mobile-title {
  @apply bg-luveLightBlue col-span-12 rounded-br-[5rem] p-6 relative text-white z-10 lg:hidden;

  h2,
  h3 {
    @apply font-medium italic text-2xl text-white;
  }
}

.grecaptcha-badge {
  @apply z-[1000];
}
