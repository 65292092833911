.awards-list {
  &__background-video-container {
    @apply after:absolute after:bg-luveBlue after:top-0 after:w-full after:h-full after:mix-blend-color;
  }

  &__content {
    // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
    // sass-lint:enable no-vendor-prefixes, no-misspelled-properties
  }

  &__award--clickable {
    @apply cursor-pointer;
  }

  &__award-modal {
    @apply
    bg-white
    opacity-0
    ease-in-out
    duration-1000
    fixed
    flex
    flex-col
    items-center
    top-full
    lg:left-1/2
    lg:-translate-x-1/2
    pb-6
    pt-8
    lg:pt-28
    px-6
    lg:px-24
    lg:w-auto
    w-full
    z-[102];

    &-link {
      @apply border
      border-luveLightBlue
      flex
      flex-row
      gap-8
      py-4.5
      px-8
      bg-white
      hover:bg-luveLightBlue
      mb-8;

      &-text {
        @apply font-medium
        text-luveLightBlue
        text-lg
        uppercase;
      }

      &:hover {
        .awards-list__award-modal-link-text {
          @apply
          bg-luveLightBlue
          text-white;
        }

        .awards-list__award-modal-link-icon {
          @apply brightness-0
          invert;
        }
      }
    }

    &-close {
      @apply bg-white
      flex
      cursor-pointer
      hover:bg-luveLightBlue
      justify-center
      p-4;

      &:hover {
        .award-list__award-modal-close-icon {
          @apply brightness-0
          invert;
        }
      }
    }

    &--open {
      @apply
      opacity-100
      top-16.5
      lg:top-1/2
      lg:-translate-y-1/2
      ease-in-out
      duration-1000;
    }
  }

  &__awards-modal-overlay {
    @apply fixed
    bg-luveBlue/95
    backdrop-blur-md
    opacity-0
    h-full
    w-full
    top-full
    ease-in-out
    duration-1000
    z-[101];

    &--open {
      @apply
      opacity-100
      top-0
      ease-in-out
      duration-1000;
    }
  }
}
