body {
  &.overlay {
    @apply bg-membersAreaGrey-overlay;

    .documents-detail__container {
      @apply opacity-60;
    }

    .documents__form-clear-filters,
    .documents-detail__container {
       @apply pointer-events-none;
    }

    .documents__form-clear-filters-mobile {
      @apply pointer-events-none;

      .filters-number {
        @apply opacity-60;
      }
    }
  }
}

.main-content {
  .documents-detail {
    &__title {
      @apply
      border-b
      border-b-luveBlue
      border-dashed
      text-luveBlue
      font-sans
      font-bold
      mb-10
      pb-8
      pt-11
      text-lg;

      &-number {
        @apply font-normal;
      }
    }
  }
}

.header--members-area {
  @apply lg:border-0 border-b border-luveBlue;
}

.members-area {
  &__container {
    @apply container lg:container-narrow flex flex-col items-center lg:justify-between pb-18 pt-4 lg:pt-6 px-7 lg:px-2 2xl:px-0;

    &--seen-recently {
      @apply items-start;
    }
  }

  &__title {
    @apply text-center text-luveGrey-lightest text-2xl;

    &-container {
      @apply items-center justify-center lg:flex hidden;
    }
  }

  &__header {
    @apply self-center px-7 md:px-14;

    &-container {
      @apply container lg:container-narrow block md:grid grid-cols-12 pt-9 lg:pt-5 pb-28;

      &--small {
        @apply py-0 lg:pb-5 lg:pt-5;
      }
    }
  }

  &__greeting {
    @apply border-0 md:border-r md:border-dashed md:border-luveLightBlue flex h-auto mb-4 md:mb-0 md:h-20 items-center mx-0 md:mr-0 md:ml-auto justify-center xl:justify-start w-full;

    &-container {
      @apply lg:block hidden;
    }

    &-message {
      @apply text-luveGrey-lightest text-lg;

      &--name {
        @apply font-bold text-3xl;
      }
    }
  }

  &__navigation {
    @apply bg-luveBlue bg-members-area bg-auto bg-no-repeat xl:bg-cover -scale-x-100 md:transform-none;

    &-top {
      @apply block lg:hidden pt-[70px] md:pt-[90px] bg-white;

      &-container {
        @apply container lg:container-narrow flex px-7 lg:hidden block;
      }

      &-title {
        @apply font-medium my-5 text-3-5xl text-luveLightBlue;
      }
    }

    &-container {
      @apply hidden lg:flex items-center lg:justify-between w-full -mt-[121px] z-50 py-6 px-11 bg-white;

      &-mobile {
        @apply flex flex-col lg:hidden w-full z-[75] -mt-[220px] md:-mt-[238px];

        // sass-lint:disable class-name-format
        .documents__form-field--id_search_query {
          @apply w-full mt-3;
        }
        // sass-lint:enable class-name-format
      }
    }

    &-mobile {
      &-search-label {
        @apply text-white text-lg font-normal mb-2 w-full;
      }

      &-accordion {
        @apply relative;
      }

      &-links-container {
        @apply hidden absolute w-full bg-white;

        &.active {
          @apply flex flex-col shadow-documentsMobileNavigation;
        }

        a {
          @apply px-4 py-3 text-sm text-membersAreaGrey;

          &.active {
            @apply bg-membersAreaGrey-selectHighlight;
          }

          & + a {
            @apply border-t border-membersAreaGrey-navigationBorder;
          }
        }
      }

      &-section-name {
        @apply text-white text-base font-normal w-full bg-luveLightBlue font-bold px-4 py-3 flex items-center cursor-pointer;

        &.active {
          @apply items-baseline;

          .caret-down {
            @apply -rotate-[135deg];
          }
        }

        .caret-down {
          @apply border-solid border-white border-r-2 border-b-2 p-0.5 rotate-45 ml-auto mb-1 w-2.5 h-2.5 ease-linear duration-200;
        }
      }
    }

    &-links {
      @apply flex flex-row items-center;

      &-container {
        @apply flex;
      }

      p {
        @apply text-sm text-luveBlue mr-5;
      }

      a {
        @apply text-membersAreaGrey text-sm p-3.5 block border-[.5px] border-membersAreaGrey;

        &:hover {
          @apply underline;
        }

        & + a {
          @apply border-l-0;
        }

        &.active {
          @apply text-white font-medium bg-luveLightBlue border-[.5px] border-luveLightBlue shadow-documentsNavigation;

          &:hover {
            @apply no-underline;
          }
        }
      }
    }
  }

  &__documents-list {
    @apply w-full z-50 relative;

    .documents {
      &__form {
        @apply
        grid
        grid-cols-24
        items-center
        gap-x-1
        w-full
        mb-5
        bg-documentsLightBlue
        px-11
        py-5
        hidden
        lg:grid;

        &.active {
          @apply absolute flex flex-col pt-0 pb-0 px-4 z-[75];

          .documents__form-see-recently {
            @apply hidden;
          }

          fieldset {
            @apply flex flex-col w-full mt-5;

            .documents__form-field-container {
              @apply mb-6 flex lg:block;
            }
          }
        }

        .documents__form--mobile-separator {
          @apply border-b border-membersAreaGrey-selectHighlight w-full block lg:hidden;
        }

        fieldset {
          @apply flex flex-wrap col-span-18;
        }

        &--drawing {
          // sass-lint:disable class-name-format
          .drawing-field-label--id_document_families,
          .drawing-field-label--id_document_models,
          .drawing-field-label--id_drawing_formats,
          .drawing-field-label--id_drawing_types,
          .drawing-field-label--id_drawing_fan_diameters,
          .drawing-field-label--id_drawing_fan_numbers,
          .drawing-field-label--id_drawing_tube_diameters {
            @apply hidden;

            &.active {
              @apply flex #{!important}; // sass-lint:disable-line no-important
            }
          }
          // sass-lint:enable class-name-format
        }

        &.active.documents__form--drawing {
          fieldset {
            .documents__form-field-container {
              @apply mb-0;

              .drawing-field-label--id_document_brands, // sass-lint:disable-line class-name-format
              .documents__form-field-label.active {
                @apply mb-6;
              }
            }
          }
        }

        &-filter-by-label {
          @apply text-membersAreaGrey text-sm col-span-3 hidden lg:block;

          &-mobile {
            @apply text-membersAreaGrey text-sm font-normal w-full bg-documentsLightBlue px-4 py-4 flex items-center cursor-pointer lg:hidden mt-4;

            &.active {
              @apply items-baseline;

              .caret-down {
                @apply -rotate-[135deg];
              }
            }

            .caret-down {
              @apply border-solid border-membersAreaGrey border-r-2 border-b-2 p-0.5 rotate-45 ml-auto mb-1 w-2.5 h-2.5 ease-linear duration-200;
            }
          }
        }

        &-field {
          @apply hidden absolute top-6 z-50;

          &-container {
            @apply relative;
          }

          &-label {
            @apply lg:mr-9 text-sm text-membersAreaGrey font-light cursor-pointer flex items-center;

            &:hover {
              @apply underline;
            }
          }

          &.active {
            @apply block;
          }

          .select2-selection {
            @apply flex rounded-none;

            .select2-selection__choice {
              @apply bg-luveLightBlue-light border-luveLightBlue text-white flex text-sm;

              .select2-selection__choice__remove {
                @apply order-1 mr-0 ml-1 text-white;
              }
            }

            &--single {
              @apply h-full;

              .select2-selection__rendered {
                @apply text-sm text-membersAreaGrey px-4 py-3 w-full #{!important}; // sass-lint:disable-line no-important

                .select2-selection__clear {
                  @apply ml-auto px-2;
                }
              }

              .select2-selection__arrow {
                @apply hidden;
              }
            }
          }

          .select2-search {
            @apply flex;
          }

          .select2-selection__rendered {
            @apply flex items-center px-4 py-3 #{!important}; // sass-lint:disable-line no-important
          }

          .select2-search__field {
            @apply flex mt-0 text-sm text-membersAreaGrey #{!important}; // sass-lint:disable-line no-important
          }

          .select2-selection__choice,
          .select2-selection__clear {
            @apply mt-0 #{!important}; // sass-lint:disable-line no-important
          }

          .select2-selection__clear {
            @apply order-1 text-sm;
          }

          .select2-container--focus .select2-selection {
            @apply border-luveGrey-dark;
          }

          .select2-selection__placeholder {
            @apply text-sm text-membersAreaGrey #{!important}; // sass-lint:disable-line no-important
          }
        }

        &-see-recently {
          @apply italic text-membersAreaGrey text-sm ml-auto col-span-3;

          &:hover {
            @apply underline;
          }
        }

        &-clear-filters {
          @apply col-span-24 hidden lg:block px-11;

          button {
            @apply flex flex-row mt-4 text-membersAreaGrey text-sm opacity-50;

            svg {
              @apply fill-luveBlue mr-1 w-5;
            }

            &:hover {
              @apply opacity-100;
            }
          }
        }

        .caret-down {
          @apply border-solid border-membersAreaGrey border-r border-b p-0.5 rotate-45 ml-2 mb-1;
        }

        .filters-number {
          @apply px-1 py-0.5 text-3xs text-white bg-luveBlue font-normal ml-1;

          &-container {
            @apply flex;
          }
        }
      }
    }

    .documents__form-clear-filters-mobile {
      @apply flex items-center mt-4 lg:hidden;

      button {
        @apply flex flex-row text-membersAreaGrey text-sm opacity-50;

        svg {
          @apply fill-luveBlue mr-1 w-5;
        }

        &:hover {
          @apply opacity-100;
        }
      }

      .filters-number-container {
        @apply text-sm text-membersAreaGrey ml-auto flex items-center;

        .filters-number {
          @apply px-1 py-0.5 text-3xs text-white bg-luveBlue font-normal ml-1;
        }
      }
    }

    .no-results {
      &-title {
        @apply font-bold text-3xl lg:text-3-5xl text-luveBlue;
      }

      &-message {
        @apply font-normal text-2xl lg:text-3xl text-luveBlue;
      }

      &-button {
        @apply text-white text-base px-8 py-3 bg-luveBlue mt-8;

        &:hover {
          @apply underline;
        }
      }
    }

    .document-detail {
      @apply border-b
      border-b-luveLightBlue
      border-dashed
      flex
      flex-col
      lg:flex-row
      justify-between
      mb-10
      pb-10;

      &__left {
        @apply flex
        flex-wrap
        mb-6
        lg:mb-0;
      }

      &__right {
        @apply hidden lg:flex flex-col min-w-[40%] max-w-[40%];
      }

      &__bottom {
        @apply flex flex-col flex-wrap lg:hidden;

        .document-detail__document-file {

          &--manual {
            @apply mb-5;

            .document-detail__document-date {
              @apply mb-2;
            }
          }

          &--wiring {
            @apply flex flex-row items-center;

            .document-detail__document-id {
              @apply flex flex-row flex-wrap h-5 mb-0 mr-10;
            }
          }

          &--software {
            .document-detail__document-date {
              @apply h-5 mr-10 mt-4;
            }
          }

          &.active {
            @apply flex flex-row;
          }
        }

        .document-detail__document-file-icon {
          @apply mr-6;
        }

        &-actions {
          @apply flex flex-wrap flex-row items-center justify-between;

          .document-detail__document-languages {
            @apply basis-full
            order-2;
          }
        }

        .language-toggle {
          @apply cursor-pointer
          flex
          font-normal
          items-center
          relative
          text-luveLightBlue
          text-xs;

          .caret-down {
            @apply border-solid border-luveLightBlue border-r border-b p-0.5 rotate-45 ml-1.5 mb-1 w-1.5 h-1.5;
          }
        }

        .language-list {
          @apply absolute -translate-y-1/2 bg-white w-full z-10 shadow-documentsSelection;

          li {
            @apply flex flex-col;

            &.active {
              .language-list__item {
                @apply bg-membersAreaGrey-activeLanguage;
              }
            }
          }
        }

        .language-list__item {
          @apply border-b border-membersAreaGrey-languageBorder last:border-0 font-normal p-4 text-membersAreaGrey;
        }
      }

      &__document-file {
        &--manual {
          @apply hidden;

          &.active {
            @apply block;
          }

          &-language {
            @apply cursor-pointer;

            &.active {
              @apply lg:underline cursor-auto;
            }
          }
        }

        &-icon svg {
          @apply fill-luveBlue
          opacity-50
          overflow-visible
          w-10;
        }
      }

      &__preview-image {
        @apply
        mr-10
        w-28;

        img {
          @apply w-full;
        }

        &--icon {
          @apply border-[.25px]
          border-luveLightBlue/20
          flex
          justify-center
          h-28
          p-[30px]
          w-28;

          svg {
            @apply fill-luveLightBlue-light;
          }
        }
      }

      &__info {
        @apply flex flex-col lg:mr-4;
      }

      &__notes {
        @apply mt-4
        w-full;

        &--desktop {
          @apply hidden lg:block;
        }

        &-title {
          @apply cursor-pointer flex items-center;

          &.active {
            .caret-down {
              @apply -rotate-[135deg] mb-0;
            }
          }

          &-label {
            @apply text-2sm text-membersAreaGrey border-b border-b-transparent;
          }

          .caret-down {
            @apply border-solid border-membersAreaGrey border-r border-b p-0.5 rotate-45 ml-2 mb-1 mb-1 w-1 h-1 ease-linear duration-200;
          }
        }

        &-description {
          @apply text-2sm text-membersAreaGrey font-light mt-2 hidden ease-linear duration-200;
        }
      }

      &__document-name {
        @apply font-medium
        font-sans
        mb-10
        text-luveBlue
        text-lg;
      }

      &__document-models {
        @apply text-luveBlue text-base italic mb-1;
      }

      &__logos {
        @apply hidden lg:flex flex-row;
      }

      &__logos-mobile {
        @apply flex flex-row lg:hidden mb-5;
      }

      &__brand-logos,
      &__platform-logos {
        @apply flex
        flex-row
        items-start
        lg:flex-col
        mr-16
        w-21
        lg:w-28;

        img {
          @apply lg:mb-6
          mr-8
          lg:mr-0
          last:mb-0;
        }
      }

      &__platform-logos {
        @apply hidden lg:flex;
      }

      &__document-languages,
      &__document-id,
      &__document-date,
      &__document-file-download,
      &__document-file-share-link,
      &__document-current-language {
        @apply font-sans
        font-light
        mb-0
        lg:mb-4
        text-membersAreaGrey
        text-sm;
      }

      &__document-id,
      &__document-current-language {
        @apply mb-2;

        span {
          @apply mr-4 last:mr-0;
        }
      }

      &__document-file-download {
        @apply
        mt-4
        h-8;
      }

      &__document-file-download,
      &__document-file-share {
        @apply flex items-center;

        .document-detail__document-file-type {
          @apply mr-2;
        }

        &-link {
          @apply
          items-center
          flex
          flex-row
          mb-0;

          &-copied {
            @apply
            border-luveLightBlue
            border-[.5px]
            text-xs
            text-membersAreaGrey
            hidden
            italic
            ml-7
            px-3.5
            py-1.5;

            &.active {
              @apply block;
            }
          }
        }

        &-size {
          @apply
          mr-1
          opacity-50;
        }

        &-icon,
        &-link-icon {
          @apply fill-luveBlue
          opacity-50
          w-5;

          svg {
            @apply fill-luveBlue
            w-full;
          }
        }
      }

      &__document-file-share-link {
        @apply ml-7;

        &-icon {
          @apply ml-1;
        }
      }

      &--picture,
      &--wiring,
      &--software {
        .document-detail__bottom {
          @apply flex flex-row lg:hidden items-center;
        }
      }

      &--software,
      &--wiring,
      &--drawing {
        .document-detail__preview-image svg {
          @apply w-[45px];
        }
      }
    }
  }

  &__document-download {
    @apply flex w-full text-luveBlue font-medium font-sans text-lg mb-3 items-start lg:flex-row flex-col;

    &-container {
      @apply mt-5 w-full;
    }

    &--name {
      @apply lg:basis-4/12;
    }

    &--language {
      @apply lg:mx-3 lg:basis-5/12 text-membersAreaGrey text-base;
    }

    &--created {
      @apply lg:ml-auto lg:lg:basis-3/12 lg:text-right text-membersAreaGrey font-normal text-base;
    }
  }
}

.pagination {
  @apply flex;

  .page-item {
    a {
      @apply w-[28px] h-[28px] sm:w-[46px] sm:h-[46px] text-membersAreaGrey flex items-center justify-center;

      &.disabled {
        @apply cursor-default;
      }
    }

    &.active a {
      @apply text-white bg-luveLightBlue;
    }

    &:not(.page-item--prev, .page-item--next, .page-item--first, .page-item--last) {
      @apply mx-0 sm:mx-1;
    }

    &--first {
      @apply ml-auto;
    }

    &--last {
      @apply mr-auto;
    }

    &--prev,
    &--next {
      a {
        @apply bg-documentsLightBlue text-luveDarkBlue;
      }
    }
  }
}

.visually-hidden {
  @apply sr-only;
}

// sass-lint:disable class-name-format
.documents__form-field--id_search_query {
  @apply w-2/5;

  input {
    @apply
    w-full
    border-[.5px]
    border-luveLightBlue-lighter
    focus-visible:border
    focus-visible:border-luveLightBlue-lighter
    focus:outline-none
    p-3
    bg-no-repeat
    bg-[right_2rem_center]
    placeholder:text-sm
    placeholder:italic
    text-sm
    text-luveBlue
    italic;
  }
}
// sass-lint:enable class-name-format

.select2-search__field {
  @apply placeholder:text-sm placeholder:text-membersAreaGrey w-full #{!important}; // sass-lint:disable-line no-important
}

.select2-dropdown {
  @apply shadow-documentsSelection rounded-none #{!important}; // sass-lint:disable-line no-important

  .select2-results__option {
    @apply text-sm text-membersAreaGrey px-4 py-3 border-b;

    &:last-of-type {
      @apply border-b-0;
    }

    &[aria-selected] {
      @apply bg-white text-membersAreaGrey #{!important}; // sass-lint:disable-line no-important
    }

    &[aria-selected='true'] {
      @apply hidden;
    }

    &:hover {
      @apply bg-membersAreaGrey-selectHighlight #{!important}; // sass-lint:disable-line no-important
    }
  }

  .select2-search {
    @apply border-b;
  }

  .select2-search__field {
    @apply border-0 text-sm text-membersAreaGrey #{!important}; // sass-lint:disable-line no-important

    &:focus-visible,
    &:focus {
      @apply outline-0;
    }
  }
}

.select2-selection__clear {
  @apply ml-2;
}

#select2-id_scheme_types-results { // sass-lint:disable-line no-ids id-name-format
  .select2-results__option {
    span {
      @apply
      flex
      items-center;
    }

    svg {
      @apply
      fill-luveBlue
      h-5
      mr-2.5
      opacity-50
      w-5;
    }

    &:hover {
      svg {
        @apply opacity-100;
      }
    }
  }
}

.wiring__form-field--id_scheme_types { // sass-lint:disable-line class-name-format
  .select2-selection__choice {
    svg {
      @apply hidden;
    }
  }
}
