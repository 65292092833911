.production-units {
  &__container {
    @apply hidden lg:flex;

    &--mobile {
      @apply lg:hidden
      bg-luveBlue;
    }
  }

  &-title {
    @apply font-medium italic text-white text-4xl py-9 px-28 absolute top-0 left-0;
  }

  &-list {
    @apply lg:min-w-production-units-sidebar-small
    xl:min-w-production-units-sidebar-medium
    2xl:min-w-production-units-sidebar-large
    lg:w-production-units-sidebar-small
    xl:w-production-units-sidebar-medium
    2xl:w-production-units-sidebar-large
    bg-luveBlue
    shadow-productionUnitsSidebar;
  }

  &-map {
    @apply bg-production-units-map
    relative
    w-full;

    &__background {
      @apply absolute z-[1]
      bg-bottom
      bg-cover
      w-full
      h-full
      top-0
      left-0;
    }
  }

  &-list {
    // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
    @apply overflow-y-auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
    // sass-lint:enable no-vendor-prefixes, no-misspelled-properties

    &-card {
      @apply py-5
      px-9
      odd:bg-production-unit-odd-card
      border-l-[10px]
      odd:border-l-[#004e8d]
      even:border-l-transparent
      hover:border-l-luveLightBlue
      transition-colors
      duration-700;

      &--mobile {
        @apply relative
        p-7;
      }

      &-overlay--mobile {
        @apply absolute
        bg-production-unit-mobile-card
        w-full
        h-full
        left-0
        top-0
        opacity-50
        z-0;
      }

      &-header--mobile {
        @apply relative
        z-10
        flex
        items-center
        mb-8;
      }

      &-header-text--mobile {
        @apply ml-7;
      }
    }

    &-country-name--mobile {
      @apply font-bold
      text-3xl
      text-white
      uppercase
      mb-1.5;
    }

    &-name {
      @apply text-luveLightBlue
      font-bold
      text-base
      mb-0.5;

      &--mobile {
        @apply relative
        z-10
        text-luveLightBlue
        font-bold
        text-base
        mb-4;
      }
    }

    &-address {
      @apply text-luveGrey
      font-condensed
      italic
      text-base
      mb-4;

      &--mobile {
        @apply text-white
        italic
        text-base;
      }
    }

    &-description {
      @apply text-luveGrey
      text-sm
      mb-6;

      &--mobile {
        @apply relative
        z-10
        text-white
        text-sm
        mb-6;
      }
    }

    &-url {
      @apply flex
      items-baseline
      w-fit;

      &--mobile {
        @apply relative
        z-10
        flex
        items-baseline;

        span {
          @apply text-luveLightBlue
          font-bold
          uppercase
          text-sm;
        }
      }

      &-arrow--mobile {
        @apply object-contain
        ml-2;
      }

      span {
        @apply text-luveLightBlue
        font-bold
        uppercase
        text-xs
        pb-0.5
        border-b
        border-b-luveLightBlue;
      }

      &--arrow {
        @apply opacity-0
        object-contain
        transition-all
        duration-300
        -ml-10;
      }
    }

    &__container {
      @apply hidden;

      &--active {
        @apply block;
      }
    }
  }

  &-country {
    @apply mb-4 xl:mb-5;

    &__image {
      @apply hidden
      max-h-full
      absolute
      top-1/2
      left-1/2
      -translate-x-1/2
      -translate-y-1/2;

      &--active {
        @apply block;
      }
    }

    &--active button {
      @apply bg-luveLightBlue;
    }

    button {
      @apply text-white
      font-medium
      text-xs
      xl:text-sm
      uppercase
      px-4
      py-1
      border
      border-luveLightBlue
      hover:bg-luveLightBlue;
    }

    &--mobile {
      @apply mb-0
      last:mr-3;

      button {
        @apply text-nowrap
        font-medium
        text-lg
        px-10
        py-4;
      }
    }

    & + .production-units-country {
      @apply ml-5 lg:ml-3 xl:ml-7;
    }
  }

  &-countries-list {
    @apply absolute
    bottom-0
    list-none
    flex
    flex-wrap
    justify-center
    w-full
    z-[2];

    &--mobile {
      @apply flex
      overflow-x-auto
      list-none
      py-8
      ml-3
      mb-3;
    }
  }
}

.production-unit {
  &-detail {
    @apply col-span-12
    overflow-hidden
    bg-luveBlue
    relative
    px-0
    lg:px-4;

    &-header {
      &__main-image {
        @apply absolute
        right-0
        z-10
        hidden
        lg:block
        lg:pl-80
        2xl:pl-0
        w-[1045px]
        h-[470px]
        after:hidden
        after:lg:block
        after:absolute
        after:top-0
        after:left-80
        after:2xl:left-0
        after:bg-unit-header-image-overlay
        after:h-full
        after:w-full;

        img {
          @apply object-cover object-left w-full h-full;
        }

        &--mobile {
          @apply block before:absolute before:bg-luveBlue before:h-1/2 before:w-7 before:-left-7 before:bottom-0 mb-12 ml-7 relative lg:hidden w-[620px] h-[222px] sm:w-[840px] md:w-[1024px];
        }
      }

      &__titles {
        @apply pl-10 sm:pl-8 mb-11 lg:my-24 lg:block laptop:pl-4 lg:pl-0 z-20;

        &-container {
          @apply container-wide lg:container flex flex-col justify-end relative min-h-80 lg:min-h-0;
        }
      }

      &__subtitle {
        @apply text-lg lg:text-2xl font-bold text-white uppercase xl:text-3xl mb-6;
      }

      &__title {
        @apply text-4xl
        lg:text-5xl
        font-bold
        text-white
        capitalize
        xl:text-6-5xl;
      }

      &__image-mobile {
        @apply block lg:hidden absolute top-0 object-cover h-full w-full;
      }

      &__overlay-mobile {
        @apply absolute top-0 z-10 w-full h-full bg-production-unit-header-mobile-overlay lg:hidden;
      }
    }

    &-main-content {
      @apply container-wide
      lg:container
      relative
      z-10
      w-full
      bg-white
      lg:before:absolute
      lg:before:h-full
      lg:before:bg-white
      lg:before:w-80
      lg:before:-ml-80
      lg:mt-44
      xl:mt-39
      pb-0
      lg:pb-17;

      &__container {
        @apply grid grid-cols-24 mb-12 lg:mb-17;
      }

      &__image {
        @apply z-20 hidden col-span-13 -mt-20 lg:block xl:-mt-40 bg-cover bg-center min-h-415;
      }

      &__description {
        @apply flex flex-col justify-end col-span-24 lg:gap-12 lg:col-span-11 pt-6 lg:pt-0 lg:px-17;

        &-text {
          @apply font-sans text-lg italic lg:text-3xl text-luveBlue tablet-max:leading-8 pb-12 lg:pb-0 px-8 lg:px-0 lg:mt-8;
        }

        &-link {
          @apply border-b-2
          border-b-luveLightBlue
          flex
          flex-row
          items-baseline
          whitespace-nowrap
          font-medium text-2xl
          text-luveBlue
          pb-2.5
          ml-8
          lg:ml-0
          w-fit;
        }

        &-link-arrow {
          @apply lg:w-0
          lg:opacity-0
          object-contain
          ml-5
          lg:ml-0
          transition-all
          duration-300;
        }
      }
    }

    &-content {
      @apply
      bg-white
      px-0
      relative
      after:absolute
      after:bg-white
      after:h-full
      after:w-80
      after:-right-80
      after:top-0
      before:absolute
      before:bg-white
      before:h-full
      before:w-80
      before:-left-80
      before:top-0;

      &__container {
        @apply container pt-6 lg:pt-14 pb-17 lg:pb-26 px-7 lg:px-0;
      }

      &-box {
        @apply hidden;

        &.active {
          @apply block;
        }

        &.production-unit-detail-documents,
        &.production-unit-detail-certificates {
          &.active {
            @apply flex;
          }
        }

        p {
          @apply mb-5;
        }

        p,
        li {
          @apply text-luveBlue
          text-lg
          lg:text-3xl;
        }

        ul {
          @apply list-disc
          mb-5;

          li {
            @apply list-inside;
          }
        }
      }
    }

    &-navigation {
      @apply flex flex-row pb-8 lg:pb-0 pl-3 md:pl-12 2xl:pl-0 last:mr-4 lg:last:mr-0 gap-5 xl:gap-20 overflow-x-auto xl:overflow-hidden list-none;
    }

    &-navigation__item {
      button {
        @apply bg-white
          flex
          items-center
          border
          border-luveLightBlue
          disabled:opacity-50
          disabled:cursor-default
          disabled:hover:bg-white
          disabled:hover:text-luveLightBlue
          col-span-3
          cursor-pointer
          font-medium
          hover:bg-luveLightBlue
          hover:text-white
          px-9
          lg:px-12
          py-4
          text-luveLightBlue
          text-center
          uppercase;
      }

      &.active {
        button {
          @apply bg-luveLightBlue
          text-white;
        }
      }
    }

    &__resource {
      &-link {
        @apply flex flex-row items-center justify-between w-full border border-luveLightBlue hover:bg-luveLightBlue lg:w-fit;

        &-text {
          @apply px-8 py-4 text-sm font-medium uppercase text-luveLightBlue md:text-lg;
        }

        &-image {
          @apply p-6 bg-luveLightBlue;
        }
      }
    }
  }

  &-blue-description {
    @apply after:absolute
    after:bg-luveLightBlue
    after:h-full
    after:w-80
    after:-right-80
    after:top-0
    bg-luveLightBlue
    container-wide
    lg:container
    hidden
    lg:block
    pb-14
    px-17
    pt-14
    lg:pr-28
    relative;

    p {
      @apply italic
        font-bold
        text-lg
        tablet-max:leading-8
        lg:text-3xl
        text-white;
    }

    &--mobile {
      @apply block
      lg:hidden
      mb-8
      pt-11
      pb-6
      px-8;

      p {
        @apply not-italic
        font-normal;
      }
    }
  }

  &-detail-overview {
    &__link {
      @apply bg-luveLightBlue
        hover:text-luveLightBlue
        hover:bg-white
        border
        border-luveLightBlue
        font-medium
        flex
        flex-row
        items-center
        gap-1.5
        mt-16
        px-5
        lg:px-12
        py-4.5
        text-lg
        text-white
        uppercase
        w-fit;

      &-image {
        @apply brightness-0
          invert
          group-hover:filter-none;
      }
    }
  }

  &-detail-certificates,
  &-detail-documents {
    @apply flex-row flex-wrap justify-center gap-y-5 gap-x-12 lg:px-0 lg:gap-y-20;
  }

  &-detail-contacts {
    &.active {
      @apply flex flex-col lg:flex-row justify-between;
    }

    &__content-container {
      @apply flex flex-col justify-between lg:pt-5 lg:pl-8 xl:pl-17;
    }

    &__content {
      @apply flex flex-row items-start gap-5;
    }

    p {
      @apply text-base font-medium;

      .contacts-font-bold {
        @apply font-bold;
      }

      a {
        @apply italic;
      }

      &.production-unit-detail-contacts__title {
        @apply text-xl text-luveLightBlue font-bold mb-5 pt-1;
      }
    }

    &__link {
      @apply border-b-2 border-b-luveLightBlue flex flex-row items-center whitespace-nowrap font-medium ml-14 lg:ml-0 mb-26 lg:mb-0 text-2xl text-luveBlue pb-2.5 w-fit;

      &-arrow {
        @apply w-0
        opacity-0
        object-contain
        transition-all
        duration-300;
      }
    }

    &__map {
      @apply border-4
      border-luveLightBlue
      h-screen
      max-h-[766px]
      w-full
      lg:max-h-[396px]
      lg:max-w-[634px];
    }
  }
}
