.homepage-header {
  @apply bg-luveBlue col-span-12 relative w-full;

  &__background-image {
    @apply hidden xs:block;

    &--mobile {
      @apply block xs:hidden;
    }
  }

  &__intro {
    @apply absolute z-10 text-white top-16 lg:top-24 3xl:top-42 flex flex-col items-center sm:items-start px-6 md:pl-14 xl:pl-28 2xl:pl-56;
  }

  &__title {
    h1 {
      @apply italic text-center sm:text-left text-white text-5xl xl:text-7xl 3xl:text-[80px];
    }
  }

  &__subtitle {
    @apply mt-16 xs:mt-6 3xl:mt-10 mb-14 sm:mb-6 md:mb-12 2xl:mb-12 pt-0 w-full sm:w-4/5 lg:w-2/3;

    p {
      @apply text-center sm:text-left text-lg xl:text-2xl 3xl:text-[35px] 3xl:leading-[46px] font-medium text-white;
    }
  }

  &__slider-text {
    p {
      @apply text-base lg:text-lg text-white mb-7;
    }

    &-content {
      @apply px-11 pr-5 md:px-22 py-6 2xl:py-11.5 md:pr-64 xl:pr-32;
    }

    &-number {
      @apply font-sans text-base md:text-3xl mb-7 text-luveLightBlue;
    }

    &-container {
      &.slick-slider {
        @apply bg-luveBlue xl:ml-28 2xl:ml-56 md:h-56 xl:h-52 2xl:h-62 relative xl:absolute bottom-0 z-20 w-full flex flex-row;
        @apply xl:w-165 #{!important}; //sass-lint:disable-line no-important
      }
    }

    &-link {
      @apply gap-2 lg:gap-5 z-50 flex flex-row items-center mr-3 cursor-pointer;

      .slider-text-link {
        &__label {
          @apply text-base lg:text-3xl font-medium text-white;
        }

        &__arrow-container {
          @apply max-w-8 flex overflow-hidden lg:pt-1;
        }

        &__arrow-content {
          &--left {
            @apply flex items-center
            translate-x-2
            sm:-translate-x-8
            ease-in-out
            duration-700;
          }

          &--right {
            @apply flex items-center -translate-x-2 ease-in-out duration-700;
          }
        }

        &__arrow {
          &--left {
            @apply ease-in-out
            duration-700
            sm:opacity-0;
          }

          &--right {
            @apply ease-in-out
            duration-700
            opacity-0
            sm:opacity-100;
          }
        }
      }

      &:hover {
        .slider-text-link__arrow-content--left {
          @apply ease-in-out
          duration-500
          translate-x-2;
        }

        .slider-text-link__arrow-content--right {
          @apply ease-in-out
          duration-500
          translate-x-6;
        }

        .slider-text-link__arrow--left {
          @apply ease-in-out
          duration-500
          opacity-100;
        }

        .slider-text-link__arrow--right {
          @apply ease-in-out
          duration-500
          opacity-0;
        }
      }
    }
  }

  &-video {
    @apply col-span-12 max-h-[539px] lg:max-h-[700px] relative;

    &__play-button {
      @apply flex flex-row items-center gap-3.5 md:gap-8 cursor-pointer hover:opacity-50 self-start;

      &-label {
        @apply text-sm md:text-3xl font-medium text-white;
      }

      img {
        @apply w-12 md:w-18 xl:w-16 2xl:w-auto;
      }
    }

    &__video-container {
      @apply fixed hidden lg:left-1/2 lg:-translate-x-1/2 top-1/2 -translate-y-1/2 z-[120];

      &.is-visible {
        @apply block;
      }
    }

    &__video-overlay {
      @apply bg-black/90 fixed hidden h-screen left-0 top-0  w-screen z-[110];

      &.is-visible {
        @apply block;
      }
    }
  }

  &__socials {
    @apply hidden xl:flex absolute z-10 flex-row items-end mb-12 gap-9 bottom-2 right-12;
  }

  &__social-logo {
    @apply brightness-200;
  }
}
