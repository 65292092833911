.newsletter-banner {
  @apply bg-white
  col-span-12
  overflow-hidden
  relative
  tablet-max:mb-3;

  &__background-image {
    @apply absolute
    h-full
    hidden
    w-full
    xl:block;

    &--mobile {
      @apply block
      lg:hidden;
    }
  }

  &__content {
    @apply container-narrow
    flex
    flex-col
    lg:flex-row
    justify-center
    items-center
    gap-11
    lg:gap-40
    lg:pb-30
    lg:pt-24
    pb-12
    pt-14
    px-9
    lg:px-0
    relative;

    &-text {
      @apply px-3
      lg:px-0;
    }
  }

  &__title {
    @apply font-normal
    lg:mb-2
    lg:text-6xl
    mb-5
    text-4xl
    text-luveBlue;
  }

  &__subtitle {
    @apply font-medium
    text-base
    text-luveBlue;
  }

  &__link {
    @apply bg-luveLightBlue
    hover:text-luveLightBlue
    hover:bg-white
    border
    border-luveLightBlue
    font-bold
    lg:font-normal
    px-5
    lg:px-9
    py-4.5
    text-lg
    text-white
    uppercase
    lg:normal-case;
  }
}

.newsletter-subscribe {
  @apply after:absolute
    after:w-full
    after:h-1/5
    lg:after:h-full
    after:bg-newsletter-overlay
    lg:after:bg-luveBlue
    after:opacity-75
    after:top-0
    after:left-0
    col-span-12
    relative;

  &__background-image {
    @apply absolute h-auto lg:h-full w-full object-cover min-h-80 top-0;
  }

  &__content {
    @apply container relative z-10 md-to-lg:max-w-none tablet-max:max-w-none;

    &-titles {
      @apply hidden lg:flex flex-col mb-22;
    }

    &-items {
      @apply grid grid-cols-12 pb-16 z-10 bg-white mt-48 lg:mt-64 relative before:absolute before:-left-full before:w-full before:h-full before:bg-white;
    }
  }

  &__title {
    @apply font-bold text-6-5xl text-white;
  }

  &__subtitle {
    @apply pt-26 mb-4 uppercase font-bold text-white text-3xl;
  }

  &__iframe-container {
    @apply col-span-12 order-2 lg:order-1 lg:col-span-7 xl:col-span-6 lg:-mt-42;
  }

  &__claim-container {
    @apply col-span-12 order-1 lg:order-2 lg:col-span-5 xl:col-span-6 px-5 lg:pl-16.5 lg:pr-26 pt-16.5;

    p {
      @apply font-normal xl:italic text-lg lg:text-3xl text-luveBlue mb-9;
    }
  }
}
