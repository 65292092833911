.homepage-global-presence {
  @apply after:absolute after:bg-luveBlue/75 after:h-full after:left-0 after:top-0 after:w-full relative;

  &__background-image {
    @apply absolute h-full object-cover w-full;

    &--mobile {
      @apply relative;
    }
  }

  &__content {
    @apply container;

    &-titles {
      @apply flex flex-col-reverse gap-2.5 pt-17.5 pb-11.5 px-12 pl-9 2xl:px-0 -mt-64 lg:mt-0 relative z-10;

      h2 {
        @apply font-bold text-4xl md:text-6-5xl text-white;
      }

      h3 {
        @apply font-bold text-lg md:text-3xl text-white;
      }
    }

    &-items {
      @apply after:absolute
      after:bg-white
      after:h-9
      before:bg-white
      before:absolute
      before:h-full
      before:w-full
      before:-left-full
      before:top-0
      bg-white
      flex
      flex-col
      lg:flex-row
      gap-5
      lg:gap-30
      pl-9
      2xl:pl-0
      pt-16.5
      pb-18
      pr-9
      lg:pr-38
      relative
      w-full
      xl:w-11/12
      z-10;
    }
  }

  &__title {
    @apply capitalize font-bold text-6-5xl text-white;
  }

  &__subtitle {
    @apply font-bold text-3xl text-white uppercase;
  }

  &__link {
    @apply bg-white
    flex
    items-center
    border
    border-luveLightBlue
    col-span-3
    cursor-pointer
    font-medium
    hover:bg-luveLightBlue
    hover:text-white
    px-12
    py-4
    text-luveLightBlue
    text-center
    uppercase;
  }

  &__bottom-strip {
    @apply after:absolute
    after:bg-luveLightBlue
    after:h-full
    after:w-full
    after:-right-full
    before:absolute
    before:bg-white
    before:h-full
    before:left-0
    before:w-9
    sm:before:w-full
    sm:before:-left-full
    bg-luveLightBlue
    container
    h-9
    relative;
  }
}
