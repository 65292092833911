@mixin blue-dot {
  @apply
  before:content-['\A']
  before:h-2
  before:w-2
  before:bg-luveLightBlue
  before:inline-block
  before:rounded-full
  before:mr-2
  before:mb-px;
}

.errorlist {
  @apply mb-4 mt-2 font-bold text-base text-langRed;
}

.password-change-done,
.password-reset-done,
.password-reset-complete {
  @apply col-span-12 mb-10;

  &__container {
    @apply container relative px-4;
  }

  &__title {
    @apply font-bold my-8 text-6xl text-luveBlue;
  }

  &__text {
    @apply text-lg my-4;

    a {
      @apply font-bold text-lg text-luveBlue hover:text-luveLightBlue;
    }
  }
}

.password-reset,
.password-reset-confirm,
.password-change,
.registration {
  @apply col-span-12 mb-10;

  &__container {
    @apply container relative px-4;
  }

  &__title {
    @apply font-bold my-8 text-6xl text-luveBlue;
  }

  &__text {
    @apply lg:grid lg:grid-cols-12;

    p {
      @apply lg:col-span-4 font-condensed text-lg text-luveGrey-dark my-4;
    }

    strong {
      @apply text-luveBlue;
    }

    a {
      @apply font-bold text-lg text-luveBlue hover:text-luveLightBlue;
    }
  }
}

.login,
.password-reset,
.password-reset-confirm,
.password-change,
.registration {
  &__form {
    @apply max-w-full md:w-1/3;

    &-field {
      @apply flex flex-col mb-4.5;

      label {
        @apply font-medium mb-2 text-luveBlue text-xl;
      }

      input {
        @apply
        focus-visible:shadow-inputBorder
        focus-visible:border-luveBlue
        focus:outline-none
        max-w-full
        px-5
        py-3
        bg-white
        border
        border-luveBlue
        text-luveBlue
        text-lg;
      }

      &-disclaimer {
        @apply italic mt-3 text-luveGrey-dark text-sm;
      }
    }

    &-submit {
      @apply
      bg-luveBlue
      border
      border-transparent
      font-medium
      mb-4
      px-16
      py-4
      hover:bg-luveLightBlue
      hover:border
      hover:border-luveBlue
      justify-self-start
      text-white
      text-xl
      transition;

      &.disabled {
        @apply
        cursor-default
        bg-luveGrey-dark
        pointer-events-none;
      }
    }
  }
}

.registration__form {
  @apply
  flex
  flex-col
  lg:gap-x-85
  lg:grid
  lg:grid-cols-2
  w-full;

  &-field {
    @apply relative;

    > input {
      @apply h-14;
    }
  }

  &-footer {
    @apply mt-4 col-span-2;

    &-pre-text,
    &-after-text {
      @apply
      font-medium
      text-luveGrey-lightDark
      text-sm;
    }

    &-pre-text {
      @apply
      w-full
      lg:w-[45%]
      xl:w-[36%];

      a {
        @apply
        font-bold
        text-luveBlue
        decoration-luveLightBlue
        underline;
      }
    }

    &-after-text {
      @apply mb-10;
    }
  }

  &-privacy,
  &-newsletter {
    @apply hidden;

    &-label {
      @apply
      before:absolute
      before:left-0
      before:border-2
      before:border-luveGrey-lightDark
      before:w-4
      before:h-4
      before:p-2.5
      before:top-1
      cursor-pointer
      flex
      mb-2
      pl-8
      relative
      w-fit;

      span {
        @apply
        font-medium
        pt-2
        text-luveGrey-lightDark
        text-sm;
      }

    }

    &:checked + label {
      @apply
      after:absolute
      after:left-[.6rem]
      after:border
      after:w-1.5
      after:h-3
      after:top-2
      after:border-luveBlue
      after:border-t-0
      after:border-r-2
      after:border-b-2
      after:border-l-0
      after:rotate-45
      before:border-luveBlue;
    }
  }

  &-pre-submit-notes {
    @apply
    font-condensed
    font-medium
    text-luveGrey-lightDark
    mb-2
    opacity-50
    text-lg
    w-full
    lg:w-[45%]
    xl:w-[36%];
  }
}

.registration__redirect {
  @apply
  font-light
  mb-22
  text-luveGrey-dark
  text-lg;

  &-link {
    @apply
    font-bold
    underline;
  }
}

.registration__bottom-notes {
  @apply font-condensed
  text-lg
  text-luveGrey-dark;

  a {
    @apply
    font-bold
    text-luveBlue
    underline
    hover:no-underline;
  }
}

.personal-area {
  &__header-container {
    @apply container-wide block md:grid grid-cols-12 py-4;
  }

  &__greeting {
    @apply border-0 md:border-r md:border-dashed md:border-luveLightBlue flex h-auto mb-4 md:mb-0 md:h-20 items-center mx-0 md:mr-0 md:ml-auto justify-center xl:justify-start w-full xl:w-45-percent;

    &-message {
      @apply text-white text-lg;

      &--name {
        @apply font-bold text-3xl;
      }
    }
  }

  &__search-form {
    @apply flex flex-col lg:flex-row;

    &-input {
      @apply
      bg-white
      bg-no-repeat
      bg-[right_20px_center]
      focus-visible:border
      focus-visible:border-luveLightBlue
      focus:outline-none
      pl-5
      pr-10
      py-3.5
      placeholder:text-luveBlue
      placeholder:italic
      placeholder:text-sm
      xl:w-2/3
      2xl:w-1/2
      w-full;

      &-container {
        @apply flex-auto ml-0 lg:ml-5;
      }
    }

    &-label {
      @apply self-center text-white text-base mb-3 lg:mb-0;
    }
  }

  &__latest-downloads {
    @apply bg-personalAreaLightBlue border-0 lg:border-luveLightBlue border-x h-full min-h-0 lg:min-h-screen ml-auto px-5 py-8 lg:px-7 lg:py-11 text-luveBlue w-full xl:w-45-percent;

    &-container {
      @apply flex bg-contain bg-no-repeat;
    }

    &-title {
      @apply font-bold text-base mb-7;
    }

    &-list {
      @apply list-none;
    }

    &-item {
      @apply font-medium text-sm mb-4 last-of-type:mb-0;
    }
  }

  &__categories-list {
    @apply gap-x-24 gap-y-20 grid grid-cols-1 md:grid-cols-2 px-14 py-11;
  }

  &__category {
    &-image {
      @apply mb-10;
    }

    &-list-item {
      @include blue-dot;
      @apply
      border-b
      border-dashed
      border-personalAreaLightBlue-dark
      first-of-type:pt-0
      text-luveBlue
      text-base
      py-4
      uppercase;

      &--subtitle {
        @apply block text-sm ml-5 normal-case;
      }

      .subcategory-joined:not(:first-of-type) {
        @include blue-dot;
        @apply before:ml-4;
      }
    }
  }

  &__subcategory {
    &-detail {
      @apply px-7 py-9;
    }

    &-title {
      @apply font-bold text-6xl text-luveBlue mb-7;
    }
  }

  &__document {
    @apply block sm:grid sm:grid-cols-documentGrid gap-x-2 py-3 items-center first-of-type:border-t border-b border-luveLightBlue;

    &-info {
      @apply mb-3 sm:mb-0;

      &--title {
        @apply mt-3 sm:mt-0 font-bold text-luveBlue text-base;
      }

      &--filename,
      &--description {
        @apply text-sm text-black;
      }

      &--size,
      &--date {
        @apply text-black;
      }

      p + p {
        @apply mt-0.5;
      }
    }

    &-preview-image {
      @apply mb-3 sm:mb-0;
    }
  }

  &__back-link {
    @apply block font-bold text-luveBlue mt-8;
  }

  .pagination {
    @apply font-bold text-luveBlue mt-4;

    .previous {
      @apply mr-4;
    }

    .next {
      @apply ml-4;
    }
  }
}

.registration__form-select-container {
  @apply
  after:absolute
  after:right-5
  after:border
  after:w-2
  after:h-2
  after:top-5
  after:border-luveBlue
  after:border-t-0
  after:border-r-2
  after:border-b-2
  after:border-l-0
  after:rotate-45
  after:z-30
  h-14
  relative;

  &--country {
    @apply after:z-40;
  }

  &:has(.registration__form-select:focus) {
    @apply after:hidden;
  }
}

.registration__form-select {
  @apply
  absolute
  bg-white
  border
  border-luveBlue
  flex
  flex-col
  h-fit
  max-h-14
  overflow-hidden
  w-full
  focus:max-h-fit
  focus:after:hidden
  z-30;

  &--country {
    @apply
    focus:overflow-y-auto
    focus:max-h-96
    z-40;
  }
}

.registration__select-option-label {
  @apply
  absolute
  box-border
  border-t
  border-luveBlue
  cursor-pointer
  flex
  font-normal
  items-center
  h-14
  mx-5
  order-2
  py-3.5
  pointer-events-none
  top-0
  whitespace-nowrap
  w-full
  z-10;

}

.registration__form-field {
  .registration__select-option-label {
    @apply font-normal mb-0 text-sm 2xs:text-base xs:text-lg;
  }
}

.registration__form-select {
  &:focus {
    .registration__select-option-label {
      @apply
      bg-white
      pointer-events-auto
      relative
      text-luveGrey-dark;
    }
  }
}

.registration__form-select {
  &:focus {
    .registration__select-option-label {
      &:hover {
        @apply text-luveBlue;
      }
    }
  }
}

.registration__select-option {
  @apply absolute bottom-0 left-0 right-0 pointer-events-none opacity-0;
}

.registration__select-option {
  &:checked + label {
    @apply bg-white border-t-0 order-1 relative z-20;
  }
}

.registration__header {
  @apply before:absolute
  before:bg-register-overlay
  before:h-full
  before:top-0
  before:w-full
  before:z-10
  bg-luveBlue
  col-span-12
  lg:mb-26
  overflow-hidden
  pt-0
  lg:pt-17
  pb-40
  2xl:pb-70
  relative;

  &-image {
    @apply absolute h-full hidden lg:block object-cover top-0 w-full;
  }

  &-content {
    @apply container grid grid-cols-12 relative px-4 z-20;
  }

  &-title {
    @apply col-span-12 xl:col-span-7 italic mt-12 lg:mt-0 mb-12 lg:mb-7 px-4 xl:px-0 text-white text-6xl lg:text-7xl;
  }

  &-uptitle {
    @apply hidden lg:block col-span-12 font-bold mb-12 text-3xl px-4 xl:px-0 text-white uppercase;
  }

  &-description {
    @apply before:-left-full
    before:absolute
    before:bg-luveLightBlue
    before:h-full
    before:top-0
    before:w-full
    bg-after-sales-270
    col-span-12
    xl:col-span-7
    px-4
    pr-8
    py-10
    xl:pl-0
    xl:pr-52
    relative;

    p {
      @apply text-white text-lg lg:text-3xl mb-6 last:mb-0;
    }
  }
}

.login {
  @apply before:absolute
  before:bg-register-overlay
  before:h-full
  before:top-0
  before:w-full
  before:z-10
  bg-luveBlue
  col-span-12
  relative;

  &__header--mobile {
    @apply pb-10
    lg:hidden
    lg:pb-0
    relative;

    .mobile-title {
      @apply z-20;
    }
  }

  &__background-image {
    @apply absolute h-full hidden lg:block object-cover top-0 w-full;

    &--mobile {
      @apply block lg:hidden;
    }
  }

  &__overlay {
    @apply absolute
  lg:bg-luveBlue
    block
    h-full
    opacity-75
    top-0
    w-full
    z-10;

    &--mobile {
      @apply before:absolute
      before:h-18
      before:bg-luveBlue
      before:-top-18
      before:w-full
      bg-login-overlay-gradient
      opacity-100
      h-2/5
      mt-16;
    }
  }

  &__titles {
    @apply container
    hidden
    lg:block
    relative
    lg:mb-21
    px-12
    lg:px-4
    pt-38
    lg:pt-17
    z-20;

    &--mobile {
      @apply block
      lg:hidden;
    }
  }

  &__title {
    @apply col-span-12
    xl:col-span-7
    lg:font-bold
    xl:px-0
    text-white
    text-3xl
    lg:text-6-5xl;
  }

  &__uptitle {
    @apply hidden lg:block col-span-12 font-bold mb-4 text-3xl px-0 text-white uppercase;
  }

  &__content {
    @apply container-wide 2xl:container flex flex-col 2xl:grid 2xl:grid-cols-12 relative 2xl:px-4 z-20;
  }

  &__form {
    @apply w-full;

    &-container {
      @apply
      bg-white
      col-span-7
      order-2
      2xl:order-1
      relative
      z-30;
    }

    &-submit {
      @apply mb-0;
    }

    &-content {
      @apply bg-white border-luveGrey-lightest border-[2.25rem] lg:border-[4rem] border-b-0 lg:border-b-0 mb-5 pt-9 px-9;
    }

    &-text {
      @apply font-condensed
      mb-7
      text-lg
      text-luveGrey-lightDark
      tracking-wide;

      strong {
        @apply text-luveBlue;
      }
    }
  }

  &__text {
    @apply font-light
    last:mt-2
    relative
    text-base
    lg:text-lg
    text-luveGrey-lightDark
    last:mb-18
    2xl:last:mb-0
    mx-2
    lg:mx-1
    px-16
    lg:px-24
    z-30;

    a {
      @apply font-bold
      text-luveBlue;
    }
  }

  &__aside-text {
    @apply before:absolute
    before:bg-white
    before:w-screen
    before:right-full
    before:h-full
    before:top-0
    bg-white
    col-span-5
    2xl:mt-48
    order-1
    2xl:order-2
    pb-14
    px-5
    lg:px-16.5
    pt-6
    lg:pt-16.5
    relative;

    p {
      @apply 2xl:italic
      mb-10
      lg:mb-5
      last:mb-0
      last:font-bold
      text-lg
      lg:text-3xl
      text-luveBlue;
    }
  }
}

.registration__header-scroll-arrow {
  @apply absolute
  animate-custom-bounce
  bottom-10
  duration-[.2s]
  ease-in-out
  flex
  hover:cursor-pointer
  hover:opacity-70
  justify-center
  transition-all
  z-10
  w-full;
}
