.after-sales {
  &-header {
    @apply
    before:absolute
    before:bg-after-sales-149
    before:h-full
    before:top-0
    before:w-full
    before:z-10
    col-span-12
    container-wide
    overflow-hidden
    relative
    xl:before:-left-2/5
    xl:before:bg-gradient-to-r
    xl:before:from-luveBlue
    xl:before:to-transparent
    xl:before:top-0
    xl:before:via-luveBlue;

    &__background-image {
      @apply
      2xl:object-left
      fixed
      h-full
      lg:top-0
      object-cover
      top-20
      w-full
      xl:absolute
      xl:object-center
      xl:scale-100;
    }

    &__content {
      @apply
      mb-46
      md:mt-28
      mt-11
      px-4
      relative
      xl:mb-64
      xl:mt-36
      z-10;
    }

    &__title {
      @apply
      font-normal
      italic
      mb-20
      md:mb-8
      md:px-4
      px-2
      text-6xl
      text-white
      xl:pl-0
      xl:text-7xl;
    }

    &__description {
      @apply
      before:-left-80
      before:absolute
      before:bg-luveLightBlue
      before:h-full
      before:top-0
      before:w-80
      bg-after-sales-270
      lg:pr-32
      lg:text-3xl
      lg:w-240
      mb-12
      md:px-5
      pb-12
      pt-14
      px-3
      relative
      text-lg
      text-white
      w-full
      xl:pl-0
      xl:w-165;
    }
  }

  &-objectives {
    @apply
    2xl:px-0
    before:absolute
    before:bg-[bottom_left]
    before:bg-auto
    before:bottom-0
    before:left-0
    before:lg:bg-cover
    before:lg:bg-left
    before:lg:opacity-100
    before:opacity-50
    before:right-0
    before:top-0
    bg-luveBlue
    col-span-12
    flex
    flex-col
    px-7
    py-11
    relative
    xl:pb-20
    xl:pt-24;

    &__description {
      @apply
      md:text-2xl
      text-lg
      text-white;


      h3 {
        @apply
        font-medium
        mb-8
        text-4xl
        text-center
        text-white;
      }

      p + p {
        @apply mt-7;
      }
    }
  }

  &__modal-overlay {
    @apply
    bg-luveBlue/80
    backdrop-blur-md
    fixed
    hidden
    h-full
    w-full
    top-0
    z-[1000];
  }

  &__contact-form-modal {
    @apply
    bg-luveBlue
    border-2
    border-white
    fixed
    hidden
    -translate-x-1/2
    -translate-y-1/2
    left-1/2
    top-1/2
    xl:w-1/4
    lg:w-1/3
    lg:py-12
    lg:px-16
    py-8
    px-12
    z-[1001]
    w-11/12
    max-h-9/10
    overflow-y-auto;
  }
}

.homepage-products-container--after-sales {
  @apply before:top-0;

  .homepage-products-container__content {
    > .homepage-product-bg-image {
      @apply mb-0;
    }
  }
}

.sustainability-social-impacts-container--after-sales {

  .sustainability-social-impact__background-image--mobile {
    @apply object-bottom;
  }

  .sustainability-social-impacts-container {
    &__title {
      @apply font-medium;
    }

    &__content {
      .sustainability-social-impact__right-box,
      .sustainability-social-impact__left-box {
        .sustainability-social-impact__content {
          @apply
          justify-center
          pt-0;
        }

        .sustainability-social-impact__title {
          @apply
          font-medium
          normal-case;
        }

        .sustainability-social-impact__description {
          @apply font-light;
        }
      }

      .sustainability-social-impact:nth-child(even) {
        .sustainability-social-impact__right-box {
          .sustainability-social-impact__content {
            @apply
            2xl:pl-92
            xl:pl-60;
          }
        }
      }

      .sustainability-social-impact:nth-child(odd) {
        .sustainability-social-impact__right-box {
          .sustainability-social-impact__content {
            @apply
            2xl:pr-92
            xl:pr-60;
          }
        }
      }

      .small-bottom-strip-box__link-content {
        @apply
        mt-8
        p-0;
      }

      .sustainability-social-impact--mobile--after-sales {
        @apply
        md:min-h-[auto]
        min-h-[800px];

        .sustainability-social-impact__top-box {
          @apply h-full;
        }

        .sustainability-social-impact__central-logo {
          @apply mb-8;
        }

        .sustainability-social-impact__title--mobile {
          @apply
          font-medium
          normal-case
          pr-0;
        }

        .sustainability-social-impact__description--mobile {
          @apply font-light;
        }
      }
    }
  }
}
