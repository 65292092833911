.sustainability-header {
  &-container {
    @apply
    before:absolute
    before:bg-gradient-to-b
    before:-top-1/2
    xl:before:bg-gradient-to-r
    xl:before:-left-2/5
    xl:before:top-0
    before:from-luveBlue
    before:via-luveBlue
    before:h-full
    before:to-transparent
    before:w-full
    before:z-10
    bg-luveBlue
    container-wide
    col-span-12
    overflow-hidden
    relative;

    &__background-image {
      @apply h-full object-cover xl:h-auto xl:scale-100 fixed xl:absolute xl:object-center w-full;
    }

    &__content {
      @apply container h-full relative px-4 z-10;

      &-titles {
        @apply grid grid-cols-12;
      }
    }

    &__title {
      @apply col-span-12 font-normal italic xl:mt-28 mb-8 pl-4 pt-16 text-5xl text-white xl:pl-0 xl:col-span-6 xl:text-7xl ;
    }

    &__subtitle {
      @apply col-span-12 pr-8 font-normal mb-28 pl-4 xl:pt-16 text-3xl text-white xl:pl-0 xl:col-span-5 xl:col-start-1;
    }
  }

  &-box {
    @apply mb-11 px-4 col-span-12 xl:mb-0 xl:col-span-4 xl:px-0 z-20;

    &:nth-child(2) {
      @apply xl:mt-12;
    }

    &:nth-child(3) {
      @apply xl:mt-24;
    }

    &__title {
      @apply font-medium mb-3 px-8 text-5xl text-white;
      text-shadow: 0 .188rem .375rem #003d79; /* This is a unique valor and it's not used anywhere else */
    }

    &__description {
      @apply
      backdrop-blur-sm
      bg-gradient-to-b
      px-12
      py-8;

      p {
        @apply mb-8 text-lg;

        strong {
          @apply font-medium;
        }
      }
    }

    &:nth-child(odd) {
      .sustainability-header-box__description {
        @apply
      from-white/40
      via-white/60
      to-white
      text-luveBlue;
      }
    }

    &:nth-child(even) {
      .sustainability-header-box__description {
        @apply
      from-luveLightBlue-light/30
      via-luveLightBlue-light
      to-luveLightBlue
      text-white;

        p {
          strong {
            @apply font-bold;
          }
        }

      }
    }
  }
}
