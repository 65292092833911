.group-history {
  &__descriptions-container {
    @apply
    bg-white
    lg:before:absolute
    lg:before:h-full
    lg:before:bg-white
    lg:before:w-80
    lg:before:-ml-80
    lg:mt-8
    xl:mt-20
    2xl:mt-40
    pb-17
    relative
    z-10;
  }

  &__blue-description {
    @apply
    after:absolute
    after:bg-luveLightBlue
    after:h-full
    after:w-80
    after:-right-80
    after:top-0
    bg-luveLightBlue
    mb-17
    lg:-mt-17
    pb-14
    px-8
    pt-12
    lg:pl-17
    lg:pr-28
    lg:pt-32
    relative
    text-lg
    tablet-max:leading-8
    lg:text-3xl
    text-white;
  }

  &__background-chart-container {
    @apply
    after:absolute
    after:bg-gradient-to-b
    after:from-luveBlue
    after:h-full
    after:to-luveLightBlue-darkest
    after:top-0 after:w-full
    overflow-hidden
    relative;
  }

  &__quote {
    &:before {
      @apply
      scale-75
      opacity-60
      lg:scale-100
      lg:opacity-100
      mr-2;
      content: url('/static/img/icons/quoteMarkLeft.png');
    }

    &:after {
      @apply
      scale-75
      opacity-60
      lg:scale-100
      lg:opacity-100
      ml-2;
      content: url('/static/img/icons/quoteMarkRight.png');
    }
  }

  &-data-container {
    @apply bg-luveBlue col-span-12 overflow-hidden pb-20 relative;

    &__title {
      @apply col-span-6 font-medium text-lg lg:text-4xl text-center text-white py-24 px-12 lg:px-48;
    }

    &__background-image {
      @apply absolute tablet-max:hidden;
    }

    &__content {
      @apply container lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-2xl relative z-10;
    }

    &__content-items {
      @apply grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-7 tablet-max:px-8;
    }
  }

  &-data {
    @apply border-l flex flex-col;
    border-image: linear-gradient(to bottom, #00a8ff, rgba(0, 0, 0, 0)) 1 100%;

    &:hover {
      .group-history-data__image {
        filter: invert(40%) sepia(97%) saturate(1450%) hue-rotate(175deg) brightness(106%) contrast(103%);
      }
    }

    &__image-container {
      @apply bg-luveLightBlue flex group-hover:bg-white justify-center mb-3 max-w-32 p-8;
    }

    &__description {
      @apply font-medium pb-20 pl-6 text-sm text-white uppercase;

      strong {
        @apply text-4xl;
      }
    }
  }
}

.modal-video {
  @apply col-span-12 max-h-[539px] lg:max-h-[700px] relative ;

  &__box {
    @apply  absolute h-full p-14 w-full z-20;
  }

  &__content {
    @apply border border-white h-full lg:px-32 lg:py-40 px-11 py-10 2xs:py-24 xl:px-72 w-full;

    .container {
      @apply flex flex-col h-full items-center justify-between;
    }
  }

  &__title {
    @apply  font-light lg:text-6xl mb-10 lg:mb-24 text-center text-white text-4xl uppercase xl:text-7xl;
  }

  &__play-button {
    @apply cursor-pointer hover:opacity-50;
  }

  &__image {
    @apply absolute -left-1/2 lg:-left-1/4 max-w-none -top-[42%] xl:left-0;

    &-container {
      @apply h-screen max-h-full max-w-full overflow-hidden relative w-screen;
    }

    &-overlay {
      @apply absolute bg-black/40 h-full top-0 w-full z-10;
    }
  }

  &__video-container {
    @apply fixed hidden lg:left-1/2 lg:-translate-x-1/2 top-1/2 -translate-y-1/2 z-[120] ;

    &.is-visible {
      @apply block;
    }
  }

  &__video-overlay {
    @apply bg-black/90 fixed hidden h-screen left-0 top-0  w-screen z-[110];

    &.is-visible {
      @apply block;
    }
  }
}
