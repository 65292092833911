.sales-contact-detail {
  &-header {
    @apply overflow-hidden
    relative
    lg:px-4;

    &__overlay {
      @apply w-full h-full bg-production-unit-header-mobile-overlay lg:bg-luveBlue/75 absolute top-0 left-0 z-20;
    }

    &__image {
      @apply lg:absolute h-full object-cover w-full z-10 lg:-mx-4;

      &-container-mobile {
        @apply h-80 overflow-hidden;
      }
    }

    &-titles {
      @apply absolute top-48 left-10 lg:top-0 lg:left-0 container lg:mb-22 lg:relative lg:pt-26 z-40;

      &__title {
        @apply text-4xl lg:text-6-5xl lg:leading-[66px] font-bold text-white;
      }

      &__subtitle {
        @apply mb-3 text-3xl uppercase leading-8 lg:font-bold text-white;
      }
    }

    &-content {
      @apply lg:container
      flex flex-col
      lg:pr-28
      relative
      z-30;

      &__description-container {
        @apply flex
        flex-col
        laptop-s-max:after:absolute
        laptop-s-max:after:bottom-0
        laptop-s-max:after:left-0
        laptop-s-max:after:w-full
        laptop-s-max:after:bg-luveBlue
        laptop-s-max:after:h-1/4
        pl-6
        pt-6
        lg:pl-0
        lg:pt-0
        lg:grid
        lg:grid-cols-12
        relative;
      }

      &__country-box {
        @apply bg-luveGrey-lightest col-span-7 laptop-s-max:max-h-90 order-2 lg:order-1 overflow-hidden relative z-40;
      }

      &__country-background {
        @apply w-full;
      }

      &__country-flag {
        @apply absolute w-18 lg:w-auto left-4.5 top-4.5 lg:left-12 lg:top-12;
      }

      &__description-text-container {
        @apply bg-white before:absolute
        before:bg-white
        before:right-0
        before:bottom-0
        before:h-full
        before:w-screen
        flex
        flex-col
        pr-6
        lg:pr-20
        lg:pt-16.5
        lg:pl-16.5
        xl:pr-26
        xl:place-self-end
        col-span-5
        mb-12
        lg:mb-0
        order-1
        lg:order-2
        relative;
      }

      &__description-text {
        @apply italic text-lg lg:text-3xl text-luveBlue mb-8 lg:mb-11 z-40;
      }

      &__description-link {
        @apply border-b-2
        border-b-luveLightBlue
        flex
        flex-row
        items-baseline
        whitespace-nowrap
        font-medium text-2xl
        text-luveBlue
        pb-2.5
        ml-0
        w-fit
        z-40;

        &-arrow {
          @apply lg:w-0
          lg:opacity-0
          object-contain
          ml-5
          lg:ml-0
          transition-all
          duration-300;
        }
      }

      &__sales-unit-buttons {
        @apply bg-white
        before:absolute
        before:bg-white
        before:h-full
        before:w-full
        before:top-0
        before:-left-full
        pt-10
        pb-4
        px-6
        lg:px-0
        lg:pt-14
        lg:pb-16.5
        relative;

        &-list {
          @apply flex
          flex-row
          gap-x-4.5
          overflow-x-auto
          pb-6
          lg:pr-4
          whitespace-nowrap;
        }
      }

      &__sales-unit-button {
        @apply bg-white
        flex
        items-center
        border
        border-luveLightBlue
        disabled:opacity-50
        disabled:cursor-default
        disabled:hover:bg-white
        disabled:hover:text-luveLightBlue
        col-span-3
        cursor-pointer
        font-medium
        hover:bg-luveLightBlue
        hover:text-white
        mt-1
        px-[30px]
        py-4.5
        text-luveLightBlue
        text-center
        text-lg
        uppercase;

        // &[href] {
        //   @apply laptop-s-max:pointer-events-none;
        // }
      }
    }
  }

  &-unit-tab {
    @apply relative lg:px-4;

    &__container {
      @apply lg:container
      relative
      flex
      flex-col
      lg:grid
      lg:grid-cols-12
      mb-4.5
      lg:mb-24
      after:bg-luveLightBlue
      after:h-full
      after:absolute
      after:top-0
      after:w-full
      after:-right-1/2
      after:content-sales-contact-lines
      after:hidden
      lg:after:block
      bg-luveLightBlue
      before:absolute
      before:bg-white
      before:h-full
      before:w-full
      before:right-full
      before:top-0
      pt-4
      lg:pt-17;
    }

    &__unit-info {
      @apply relative
      lg:col-span-6
      flex
      flex-col
      bg-luveGrey-lightest
      before:absolute
      before:bg-luveGrey-lightest
      before:h-full
      before:w-full
      before:right-full
      before:top-0
      pt-18
      pb-40
      px-8
      lg:px-0
      lg:py-30
      w-full
      lg:w-auto;

      &-container {
        @apply flex
        flex-row
        gap-5
        items-start
        laptop-s-max:justify-center;
      }

      &-title {
        @apply font-bold
        text-xl
        text-luveLightBlue
        leading-6
        mb-7
        pt-1;
      }

      &-logo {
        @apply min-w-sales-contacts-unit-info-logo;
      }

      &-text {
        p {
          @apply text-base
          lg:text-xl
          lg:leading-6
          mb-5
          text-luveBlue;
        }

        .contacts-font-bold {
          @apply font-bold
          italic
          text-base
          lg:text-lg
          lg:leading-6
          text-luveBlue;
        }
      }
    }

    &__unit-link {
      @apply bg-luveLightBlue
      hover:text-luveLightBlue
      hover:bg-white
      border
      border-luveLightBlue
      font-medium
      flex
      flex-row
      items-center
      justify-center
      gap-1.5
      mt-2.5
      px-5
      lg:px-12
      py-4.5
      text-lg
      text-white
      uppercase
      w-full
      lg:w-fit;

      &-image {
        @apply brightness-0
        invert
        group-hover:filter-none;
      }
    }

    &__unit-contacts {
      @apply col-span-6
      flex
      flex-col
      justify-start
      laptop-s-max:items-center
      lg:pl-24
      xl:pl-46
      laptop-s-max:w-full
      pb-10
      lg:py-30
      pt-12
      px-8
      z-40;

      &-container {
        @apply max-w-full;
      }

      &-title {
        @apply font-medium
        text-xl
        lg:text-3xl
        lg:leading-8
        mb-5
        text-white;
      }

      &-text {
        @apply flex
        flex-col;

        p {
          @apply font-light
          mb-5
          lg:mb-7
          text-base
          lg:leading-5
          lg:text-xl
          text-white
          text-wrap;
        }

        a {
          @apply font-medium
          text-base
          lg:leading-6
          lg:text-xl
          text-white
          hover:underline;

          &[href^='mailto:'] {
            @apply italic;
          }

          &.sales-contact-detail-unit-tab__unit-contacts-link[href^='mailto:'] {
            @apply not-italic;
          }
        }
      }

      &-link {
        @apply bg-none
        hover:bg-white
        border
        border-white
        font-medium
        px-20
        md:px-28
        py-4
        text-base
        text-white
        text-center
        hover:text-luveLightBlue
        w-full
        lg:w-fit;
      }
    }

    &__overlay-text {
      @apply absolute
      bottom-0
      font-condensed
      font-bold
      left-0
      3xl:-left-1/2
      leading-12
      opacity-10
      text-luveBlue
      text-8xl
      uppercase;
    }

    &__logo {
      @apply lg:absolute
      hidden
      xl:block
      top-1/2
      left-1/2
      -translate-x-1/2
      -translate-y-1/2
      z-40;
    }
  }
}
