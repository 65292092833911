.box {
  @apply bg-luveLightBlue
  col-span-12
  flex
  flex-col
  lg:flex-row
  lg:col-span-6
  justify-between
  lg:h-85
  relative
  tablet-max:mb-3;

  &-container {
    @apply grid grid-cols-12;
  }

  &-content {
    @apply col-span-12
    sm:col-span-7
    flex
    flex-col
    pl-8
    pt-12
    xl:pt-0
    xl:pl-20
    pr-12
    justify-center;
  }

  &-title {
    &__logo {
      @apply mb-12
      self-start;

      &-container {
        @apply flex
        flex-row
        justify-between;
      }

      &-arrow {
        @apply sm:hidden
        self-start
        mt-3;
      }
    }
  }

  &-image {
    &__image {
      @apply hidden
      sm:block;
    }

    &__image--mobile {
      @apply sm:hidden;
    }

    &__container {
      @apply col-span-12
      sm:col-span-5
      flex
      flex-row
      justify-end
      self-end;

      &--mobile {
        @apply col-span-12
        flex
        flex-row
        justify-end
        self-end
        sm:hidden;
      }
    }
  }

  &-link {
    @apply z-10;

    &__content {
      @apply flex flex-row pl-6 pb-8;

      &:hover {
        .box-link__title-logo {
          @apply
          ease-in-out
          duration-500
          rotate-360;
        }

        .box-link__arrow-content--left {
          @apply
          ease-in-out
          duration-500
          translate-x-2;
        }

        .box-link__arrow-content--right {
          @apply ease-in-out
          duration-500
          translate-x-6;
        }

        .box-link__arrow--left {
          @apply
          ease-in-out
          duration-500
          opacity-100;
        }

        .box-link__arrow--right {
          @apply ease-in-out
          duration-500
          opacity-0;
        }
      }
    }

    &__title {
      @apply flex flex-row font-medium text-xl lg:text-3xl text-white mr-3;

      &:after {
        @apply hidden sm:inline-block ml-11;
        content: url('/static/img/icons/arrow.png');
      }

      &-logo {
        @apply hidden mr-5 self-center rotate-0 duration-500 ;
      }
    }

    &__arrow-container {
      @apply max-w-8 flex overflow-hidden;
    }

    &__arrow-content {
      &--left {
        @apply flex items-center
        -translate-x-8
        ease-in-out
        duration-700;
      }

      &--right {
        @apply flex items-center -translate-x-2 ease-in-out duration-700;
      }
    }

    &__arrow {
      &--left {
        @apply ease-in-out
        duration-700
        opacity-0;
      }

      &--right {
        @apply ease-in-out
        duration-700
        opacity-100;
      }
    }
  }
}

.box--medium {
  @extend .box;
  @apply col-span-12 sm:col-span-6 lg:col-span-3 sm:m-3 sm:mt-0 md:m-0;

  .box {
    &-container {
      @apply flex
      flex-col
      h-full
      justify-between;
    }

    &-content {
      @apply pt-12
      xl:pl-11;
    }

    &-title {
      &__logo {
        @apply mb-4;
      }
    }

    &-image {
      &__container {
        @apply col-span-12;
      }
    }

    &-link {
      &__content {
        @apply p-0;
      }

      &__title {
        &:after {
          @apply hidden;
        }
      }

      &__arrow-container {
        @apply absolute
        top-16
        right-11;
      }
    }
  }
}

.box--small {
  @extend .box-link;
  @apply after:absolute
  after:bg-gradient-to-r
  after:from-luveBlue
  after:h-full
  after:to-transparent
  after:top-0
  after:w-full
  text-white
  col-span-12 sm:col-span-6 lg:col-span-3 h-85 relative flex flex-col justify-end tablet-max:m-3 tablet-max:mt-0;

  .box {
    &-image {
      @apply absolute h-full object-cover w-full;
    }

    &-link {
      @apply relative;

      &__title {
        &:after {
          @apply hidden;
        }

        &-logo {
          @apply block;
        }
      }
    }
  }
}

.box--full-width {
  @apply col-span-12 relative h-152 xl:h-auto;

  .box-title-container {
    @apply absolute flex h-full pt-28 justify-center items-end xl:items-start pb-16 pl-12 pr-12 w-full;

    p {
      @apply italic text-white text-6xl;
    }
  }

  .box-image {
    @apply h-full object-cover object-[70%];
  }
}

.box--no-link {
  @apply col-span-12 lg:col-span-6 min-h-100 relative;

  .box-content {
    @apply h-full px-12 lg:px-16 2xl:pr-80 3xl:pr-96 relative z-10;
  }

  .box-subtitle {
    @apply font-medium mb-8 text-4xl uppercase text-white;
  }

  .box-title {
    @apply italic text-3xl lg:text-6xl text-white;
  }

  .box-image {
    @apply  absolute h-full left-0 object-cover object-right top-0 w-full hidden lg:block;

    &--mobile {
      @apply  absolute h-full left-0 object-cover object-right top-0 w-full lg:hidden;
    }
  }
}
