.error-404,
.error-500 {
  @apply col-span-12 mb-10;

  &__container {
    @apply container relative;
  }

  &__title {
    @apply mx-4 font-bold my-8 text-7xl text-luveBlue hidden lg:block;
  }

  &__text {
    @apply mx-4 text-lg my-8;
  }

  &__link {
    @apply mx-4 text-lg text-luveBlue;
  }
}
