.sales-contacts {
  &-container {
    @apply hidden lg:flex;

    &--mobile {
      @apply lg:hidden
      bg-luveBlue;
    }
  }

  &-cluster {
    @apply hidden;

    &--active {
      @apply block;
    }

    &-image {
      @apply hidden
      max-h-full
      absolute
      top-1/2
      left-1/2
      -translate-x-1/2
      -translate-y-1/2;

      &--active {
        @apply block;
      }
    }

    &-nav-item {
      @apply mb-4 xl:mb-5;

      &--active button {
        @apply bg-luveLightBlue;
      }

      button {
        @apply text-white
        font-medium
        text-xs
        xl:text-sm
        uppercase
        px-4
        py-1
        border
        border-luveLightBlue
        hover:bg-luveLightBlue;
      }

      &--mobile {
        @apply mb-0
        last:mr-3;

        button {
          @apply text-nowrap
          font-medium
          text-lg
          px-10
          py-4;
        }
      }

      & + .sales-contacts-cluster-nav-item {
        @apply ml-5 lg:ml-3 xl:ml-7;
      }
    }
  }

  &-subcluster,
  &-subcluster-country {
    @apply pt-4
    pb-8
    pr-9
    lg:pl-[46px]
    lg:ml-[-10px]
    lg:border-l-[10px]
    lg:border-l-transparent
    lg:hover:border-l-luveLightBlue
    lg:hover:pl-9
    lg:hover:ml-0
    relative;

    &--mobile {
      @apply p-8;
    }

    &--hidden {
      @apply hidden;
    }

    &--overlay {
      @apply absolute
      bg-sales-contact-subcluster
      w-full
      h-full
      left-0
      top-0
      opacity-[.35]
      z-0;
    }

    &-image {
      @apply hidden
      max-h-full
      absolute
      top-1/2
      left-1/2
      -translate-x-1/2
      -translate-y-1/2;

      &--active {
        @apply block;
      }
    }

    &-header {
      @apply flex;

      &__image-container {
        @apply w-[40%]
        xs-to-md:w-[30%]
        md-to-lg:w-1/4
        mr-6;
      }
    }

    &__name,
    &__sales-area-countries-title,
    &-country__name {
      @apply text-luveLightBlue
      font-bold
      text-base
      uppercase
      mb-0.5;

      &--mobile {
        @apply relative
        z-10
        text-luveLightBlue
        font-bold
        text-base
        mb-4;
      }
    }

    &__sales-area-countries {
      @apply text-luveGrey-lightest
      text-base;

      &--mobile {
        @apply text-white;
      }

      &-title {
        @apply mt-12 mb-2;
      }
    }

    &__subtitle {
      @apply text-white
      font-condensed
      italic
      text-base
      mb-4
      relative
      z-10;

      &--mobile {
        @apply text-white;
      }
    }

    &__sales-contact,
    &__explore-map-button {
      @apply flex
      items-baseline
      w-fit
      z-[1]
      relative;

      &-arrow--mobile {
        @apply object-contain
        ml-2;
      }

      span {
        @apply text-luveLightBlue
        font-bold
        uppercase
        text-xs
        pb-0.5
        border-b
        border-b-luveLightBlue;
      }

      &--arrow {
        @apply opacity-0
        object-contain
        transition-all
        duration-300
        -ml-10;
      }

      &--mobile {
        @apply flex
        pt-0
        mt-8;

        span {
          @apply border-b-0
          text-sm;
        }
      }
    }

    &__explore-map-button {
      @apply pt-4;
    }

    &-detail {
      @apply hidden;

      &--active {
        @apply block;
      }
    }
  }

  &-title {
    @apply font-medium italic text-white text-4xl py-9 px-4 xl:px-10 2xl:px-14 absolute top-0 left-0;
  }

  &-elements-list {
    @apply lg:min-w-production-units-sidebar-small
    xl:min-w-production-units-sidebar-medium
    2xl:min-w-production-units-sidebar-large
    lg:w-production-units-sidebar-small
    xl:w-production-units-sidebar-medium
    2xl:w-production-units-sidebar-large
    bg-luveBlue
    shadow-productionUnitsSidebar
    overflow-y-auto;

    // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
    // sass-lint:enable no-vendor-prefixes, no-misspelled-properties
  }

  &-map {
    @apply bg-production-units-map
    relative
    w-full;

    &__background {
      @apply absolute z-[1]
      bg-bottom
      bg-cover
      w-full
      h-full
      top-0
      left-0;
    }
  }

  &-legend {
    @apply absolute
    bottom-0
    left-0
    mb-4
    xl:mb-5
    ml-4
    xl:ml-10
    2xl:ml-14
    hidden
    lg:block;

    &__title {
      @apply font-medium
      italic
      text-white
      text-3xs
      xl:text-xs
      2xl:text-sm
      mb-2
      xl:mb-3
      2xl:mb-4;
    }

    &__item {
      @apply flex
      items-center;

      &--hidden {
        @apply hidden;
      }

      &-box {
        @apply w-sales-contacts-legend-box-mobile
        h-sales-contacts-legend-box-mobile
        xl:w-sales-contacts-legend-box
        xl:h-sales-contacts-legend-box
        bg-luveBlue
        relative
        z-10
        after:absolute
        after:w-full
        after:h-full
        after:bg-luveLightBlue
        after:border-2
        after:border-white;

        &--contact {
          @apply after:opacity-100;
        }

        &--countries {
          @apply after:opacity-50;
        }
      }

      & + .sales-contacts-legend__item {
        @apply mt-2 xl:mt-3 2xl:mt-4;
      }

      &-title {
        @apply font-medium
        italic
        text-white
        text-3xs
        xl:text-xs
        2xl:text-sm
        ml-2
        xl:ml-3;
      }
    }
  }

  &-clusters-list {
    @apply absolute
    bottom-0
    list-none
    flex
    flex-wrap
    justify-center
    w-full
    z-[2];

    &--mobile {
      @apply flex
      overflow-x-auto
      list-none
      py-8
      ml-3
      mb-3;
    }
  }
}
