.sustainability-award {
  &__logo {
    @apply col-span-4 flex items-center last:col-span-6 md:last:col-span-4;

    &:nth-last-child(2) {
      @apply order-last md:-order-none col-span-6 col-start-4 md:col-start-auto md:col-span-4 justify-center;
    }

    &:nth-last-child(3) {
      @apply col-span-6 md:col-span-4;
    }
  }

  &-container {
    @apply after:absolute after:bg-gradient-to-t after:w-full after:h-full after:from-luveBlue after:via-transparent after:to-transparent after:bottom-0 container-wide col-span-12 bg-gradient-to-b from-luveLightBlue to-luveBlue relative -mt-0.5;

    &__video {
      @apply h-full object-cover;
    }
  }
}
