.app-blog {
  @apply col-span-12 container flex flex-col justify-center mb-10 relative;

  .blog-list {
    &__header {
      @apply mx-4 sm:flex sm:flex-row sm:justify-between;

      .archive-select {
        @apply mb-4 sm:my-auto sm:text-left text-right;

        select {
          @apply bg-luveGrey h-9 leading-9 pl-3 pr-10 rounded;
        }
      }
    }

    &__title {
      @apply font-bold my-8 text-7xl text-luveBlue;
    }

    &__post-items-container {
      @apply grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-8 mx-4;

      .post-item {
        @apply bg-luveGrey flex flex-col p-4;

        &__date {
          @apply mb-3 text-luveLightBlue;
        }

        &__title {
          @apply font-bold mb-5 text-lg text-luveBlue;
        }

        &__lead {
          @apply mt-auto text-sm;
        }

        &__read-more {
          @apply font-bold mt-2 text-right text-sm text-luveBlue;
        }
      }
    }

    &__back {
      @apply font-bold m-4 text-luveBlue;
    }

    &__pagination {
      @apply font-bold m-4 text-luveBlue;
    }
  }

  .post-detail {
    @apply mb-10 mx-4;

    &__title {
      @apply font-bold md:text-6xl my-8 text-4xl text-luveBlue;
    }

    &__content {
      @apply text-lg;

      img {
        @apply mb-6;
      }

      ul {
        @apply list-disc pl-5;
      }

      * + * {
        @apply mt-3 text-black;
      }
    }

    &__back {
      @apply font-bold mt-4 text-lg text-luveBlue;
    }
  }
}
