.header {
  &__content {
    @apply container flex flex-row items-center lg:justify-between pb-3.5 pt-4 lg:pb-4 lg:pt-6 px-7 lg:px-2 2xl:px-0;

    &.header__content--personal-area {
      @apply lg:container-narrow;
    }

    svg {
      @apply mr-0 lg:mr-3;

      path {
        @apply fill-luveBlue;
      }
    }
  }

  &__actions {
    @apply flex flex-row;
  }

  &__login {
    @apply items-center flex font-medium text-luveBlue hover:text-luveLightBlue;

    &-icon--logged {
      @apply rotate-180 mr-3;
    }
  }

  &__language {
    @apply items-center flex cursor-pointer font-medium text-luveBlue hover:text-luveLightBlue;
  }

  &__title--personal-area {
    @apply text-luveLightBlue font-medium text-3xl hidden lg:block;
  }

  .language-chooser {
    @apply absolute flex-col-reverse h-full top-0 right-0 lg:right-auto;

    .lang {
      @apply bg-white flex items-center justify-center h-1/2 px-5 font-medium text-sm text-luveBlue;

      &__link {
        @apply flex
        w-full;

        svg {
          @apply mr-2 lg:mr-3 rounded-full;
        }

        &:hover {
          @apply text-luveLightBlue;

          svg {
            path {
              @apply fill-luveLightBlue;
            }
          }
        }
      }

      &--current {
        @apply bg-luveBlue order-1 text-white;

        .lang__link {
          @apply flex
          w-full;

          &:hover {
            @apply text-white;
          }

          svg path {
            @apply fill-white;
          }
        }
      }
    }
  }

  &__mobile-menu {
    @apply absolute flex flex-col top-16.5 md:top-21.5 -left-full h-dvh w-full bg-luveBlue overflow-scroll;

    &-icon {
      @apply cursor-pointer flex justify-center items-center w-7;
    }
  }
}
