.homepage-applications-container {
  @apply bg-white overflow-hidden relative tablet-max:mb-3;

  &__background-image {
    @apply absolute h-full w-full scale-110 hidden xl:block;
  }

  &__content {
    @apply container flex flex-col;
  }

  &__title {
    @apply font-medium mb-8 py-16 text-4xl text-luveBlue text-center uppercase;
  }
}

.homepage-application {
  @apply
  col-span-6
  z-10;
}
