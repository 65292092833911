.development-entries {
  &__video-container {
    @apply absolute
    after:absolute
    after:h-full
    after:bg-gradient-to-r
    after:from-transparent
    after:via-luveDarkBlue
    after:to-luveDarkBlue
    after:w-3/4
    after:left-1/4
    after:top-0
    h-full
    -left-[26rem]
    overflow-hidden;

    video {
      @apply object-cover;
    }
  }

  &__video {
    @apply h-full mix-blend-luminosity;
    transform: matrix(0, -1, 1, 0, 0, 0);
  }

  &__title {
    text-shadow: 0 0 4rem black;

    h2 {
      @apply italic lg:text-4xl 2xl:text-8xl text-white;

      strong {
        @apply font-bold lg:text-5xl 2xl:text-9xl;
      }
    }
  }

  &__container {
    // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
    // sass-lint:enable no-vendor-prefixes, no-misspelled-properties
  }

  &__entry {
    @apply cursor-pointer;

    &-modal {
      @apply
      bg-white
      opacity-0
      ease-in-out
      duration-1000
      transition-transform
      flex-col
      items-start
      lg:w-1/2
      mx-auto
      w-full
      mt-64
      mb-4;

      &-content {
        @apply
        pb-6
        pt-8
        lg:pt-12
        px-6
        lg:px-16
        w-full
        relative;
      }

      &-description {
        @apply font-normal
        mb-14
        text-lg
        text-luveBlue;
      }

      &-link {
        @apply border
        border-luveLightBlue
        flex
        flex-row
        gap-8
        py-4.5
        px-8
        bg-white
        w-fit
        hover:bg-luveLightBlue;

        &-text {
          @apply font-medium
          text-luveLightBlue
          text-lg
          uppercase;
        }

        &:hover {
          .development-entries__entry-modal-link-text {
            @apply bg-luveLightBlue
            text-white;
          }

          .development-entries__entry-modal-link-icon {
            @apply brightness-0
            invert;
          }
        }
      }

      &-close {
        @apply bg-luveLightBlue
        cursor-pointer
        hover:bg-white
        p-4
        absolute
        top-3
        right-3;

        &-icon {
          @apply brightness-0
          invert;
        }

        &:hover {
          .development-entries__entry-modal-close-icon {
            @apply brightness-100
            invert-0;
          }
        }
      }

      &--open {
        @apply
        opacity-100
        ease-in-out
        duration-1000;
      }
    }
  }

  &__entry-modal-overlay {
    @apply fixed
    bg-luveBlue/95
    backdrop-blur-md
    opacity-0
    top-full
    ease-in-out
    duration-1000
    z-[101];

    &--open {
      @apply opacity-100
      inset-0
      overflow-y-auto
      ease-in-out
      duration-1000;
    }
  }

  .mobile-title--hidden {
    @apply h-0 p-0;
  }
}
