.slick-slide {
  @apply relative;

  img {
    &.slick-slide-img {
      @apply hidden sm:block;

      &-overlay {
        @apply absolute bottom-0 z-10;
      }

      &--mobile {
        @apply block sm:hidden max-h-152 object-cover w-full;
      }
    }
  }
}

.slide-controls {
  @apply flex flex-row-reverse md:flex-col w-40 md:h-56 xl:h-52 2xl:h-62 gap-x-2.5;

  &__container {
    @apply absolute flex flex-row xl:left-165 2xl:left-240 bottom-3 right-5 md:bottom-0 md:right-0;
  }
}

.slick-arrow {
  @apply bg-luveLightBlue cursor-pointer hover:bg-luveDarkBlue-light text-white md:h-1/2 px-6 py-4 md:p-10 2xl:p-12 z-20 flex justify-center;
}


.prev-slide,
.next-slide {
  img {
    @apply md:h-[30px] md:w-[30px];
  }

  &--desktop {
    @apply hidden md:block;
  }

  &--mobile {
    @apply block md:hidden;
  }
}
