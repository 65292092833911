.navigation:not(.navigation__mobile) {
  .navigation__item {
    @apply relative;

    &-link {
      @apply block font-medium py-4 px-4 xl:px-5 2xl:px-6 text-2sm text-white uppercase;
    }

    &:hover {
      @apply bg-gradient-to-r from-luveBlue-clear to-luveBlue-darker cursor-pointer;

      > .navigation__item-submenu-container {
        @apply block;
      }
    }

    &-submenu-container {
      @apply absolute bg-gradient-to-b from-luveBlue to-luveLightBlue border-t-4 border-luveLightBlue hidden left-0 w-max;

      .navigation__subitem {
        @apply py-3 px-8 last:mb-3 uppercase hover:bg-gradient-to-r hover:from-luveLightBlue hover:to-luveBlue;

        a {
          @apply font-medium flex flex-col text-2sm text-white;
        }

        &-subtitle {
          @apply block mt-1 normal-case text-xs;
        }
      }
    }

    &:last-child {
      .navigation__item-submenu-container {
        @apply lg:-left-[7.5rem] 2xl:left-0;
      }
    }
  }
}

.navigation__mobile {
  @apply py-10 px-12 z-[100];

  // sass-lint:disable no-vendor-prefixes, no-misspelled-properties
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
    // sass-lint:enable no-vendor-prefixes, no-misspelled-properties

  &-logos {
    @apply border-y border-y-white/60 grid grid-cols-3 bg-luveBlue mx-6 px-12 py-9 gap-x-12 gap-y-6 items-center;
  }

  &__logo img {
    @apply mx-auto;
  }

  &-actions {
    @apply border-b-10 border-luveLightBlue flex justify-between items-center px-12 py-7;

    .navigation__mobile-login {
      @apply flex items-center text-white font-medium text-lg;

      svg {
        @apply mr-4;

        path {
          @apply fill-white;
        }
      }
    }
  }

  &-close {
    @apply cursor-pointer;
  }

  .navigation__item {
    @apply mb-10 last:mb-0;

    &-link {
      @apply mb-10 font-medium text-3xl text-white uppercase cursor-pointer;
    }
  }

  .navigation__item-submenu-container {
    @apply hidden;
  }

  .navigation__subitem {
    @apply text-white uppercase first-of-type:mt-4 mt-2 font-medium text-lg;

    &-subtitle {
      @apply block normal-case text-xs;
    }
  }

  &-footer {
    @apply min-h-76;
  }
}
