.red-solutions {
  &-container {
    @apply bg-white col-span-12 h-162 tablet-max:h-[50rem] relative;

    &__background-image {
      @apply absolute w-full hidden xl:block;
    }

    &__title {
      @apply font-bold pt-17 mb-12 text-luveBlue text-4xl text-center uppercase;
    }

    &__content-items {
      @apply container-narrow flex flex-row justify-center relative;
    }
  }
}

.red-solution {
  &-box {
    &__image-container {
      @apply mr-5 mb-12 relative;
    }

    &__line {
      @apply absolute bg-gradient-to-b from-transparent via-luveBlue to-luveBlue h-full left-1/2 w-0.5;
    }

    &__description {
      @apply absolute bg-luveBlue border border-luveLightBlue font-normal xl:left-40 left-0 py-6 px-12 right-48 text-base text-white w-full xl:w-auto ;
    }
  }
}
