.common-styles {
  color: #003b75;
  line-height: 1.1;
  margin: 10px 0;
}

.simple-content-block {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .common-styles;
  }

  h1,
  .h1 {
    font-size: 46px;
  }

  h2,
  .h2 {
    font-size: 38px;
  }

  .heading2 {
    color: #003b75;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -.14px;
    line-height: 38px;
  }

  .lead-text {
    color: #003b75;
    font-size: 24px;
    letter-spacing: -.12px;
    line-height: 38px;

    &.content p {
      @extend .lead-text;
    }
  }

  .heading3 {
    color: #d40009;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.4px;
    line-height: 25px;
  }

  .content {
    p {
      color: #003b75;
      font-size: 18px;
      letter-spacing: -.14px;
      line-height: 26px;
      margin-bottom: 26px;

      &.lead-text {
        @extend .lead-text;
      }
    }

    .heading3 {
      margin-bottom: 26px;
    }
  }
}
